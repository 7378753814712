import { CButton, CCard, CCardBody, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormTextarea, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { Spinner } from 'react-bootstrap';

const RMDamageEntries = () => {

    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [storageLocations, setStorageLocations] = useState([]);
    const [formData, setFormData] = useState({
        storageLocation: "",
        remark: "",
        centralStockTransferData: []
    });
    const [rawMaterials, setRawMaterials] = useState([]);
    const [selectedRawMaterial, setSelectedRawMaterial] = useState(null);
    const [transferQty, setTransferQty] = useState("");
    const [selectedRawMaterialAddMore, setSelectedRawMaterialAddMore] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch Storage Locations
    useEffect(() => {
        getNewStorageLocationData();
    }, []);

    const getNewStorageLocationData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("storage-location/getCurrentStock", { headers });
            if (response?.data?.data?.storageLocations) {
                setStorageLocations(response?.data?.data?.storageLocations);
            }
        } catch (error) {
            toast.error("Failed to fetch storage location data.", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        }
    };

    const storageLocationOptions = storageLocations.map((location) => ({
        value: location._id,
        label: `${location.name} - ${location.city}, ${location.state}`,
    }));

    // Handle Storage Location Selection
    const handleStorageLocationChange = (option) => {
        setFormData({
            ...formData,
            storageLocation: option?.value || "",
            centralStockTransferData: [],
        });

        const selectedStorage = storageLocations.find((loc) => loc._id === option?.value);
        setRawMaterials(selectedStorage?.stockInfo || []);
    };

    // Raw Material Options
    const rawMaterialOptions = rawMaterials
        .filter((material) => 
            !formData.centralStockTransferData.some(
                (item) => item.rawMaterialId === material.rawMaterial._id
            )
        )
        .map((material) => ({
            value: material.rawMaterial._id,
            label: material.rawMaterial.name,
            unit: material.rawMaterial.unit,
            currentStock: material.currentStock,
        }));

    const rawMaterialOptionsAddMore = rawMaterials
        .filter((material) => 
            !formData.centralStockTransferData.some(
                (item) => item.rawMaterialId === material.rawMaterial._id
            )
        )
        .map((material) => ({
            value: material.rawMaterial._id,
            label: material.rawMaterial.name,
            unit: material.rawMaterial.unit,
            currentStock: material.currentStock,
        }));

    // Handle Raw Material Selection
    const handleRawMaterialChange = (option) => {
        setSelectedRawMaterial(option);
        setTransferQty("");
    };

    // Handle Transfer Quantity Change
    const handleTransferQtyChange = (e) => {
        setTransferQty(e.target.value);
    };

    // Add Raw Material Entry
    const handleAddMore = () => {
        if (!selectedRawMaterial || !transferQty) {
            toast.error("Please select a raw material and enter transfer quantity.", {
                position: "top-right",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
        console.log("selectedRawMaterial :", selectedRawMaterial);
        

        const newEntry = {
            rawMaterialId: selectedRawMaterial.value,
            rawMaterialName: selectedRawMaterial.label,
            unit: selectedRawMaterial.unit,
            currentStockQuantity: selectedRawMaterial.currentStock,
            transferQuantity: transferQty,
        };

        setFormData({
            ...formData,
            centralStockTransferData: [...formData.centralStockTransferData, newEntry],
        });

        setSelectedRawMaterial(null);
        setTransferQty("");
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

        
            // Remove 'unit' and 'rawMaterialName' from centralStockTransferData
            // const cleanedFormData = {
            //     ...formData,
            //     centralStockTransferData: formData.centralStockTransferData.map(item => {
            //         const { unit, rawMaterialName, ...rest } = item; // Destructure and remove unwanted properties
            //         return rest; // Return the item without 'unit' and 'rawMaterialName'
            //     })
            // };

            // Remove 'unit', 'rawMaterialName', and 'currentStockQuantity' from centralStockTransferData
            const cleanedFormData = {
                ...formData,
                centralStockTransferData: formData.centralStockTransferData.map(item => {
                    const { unit, rawMaterialName, currentStockQuantity, ...rest } = item; // Destructure and remove unwanted properties
                    return rest; // Return the item without 'unit', 'rawMaterialName', and 'currentStockQuantity'
                })
            };

            const response = await axios.post("/stock-transactions/debitDamageStockFromCentral", cleanedFormData, { headers });
            if (response?.data?.status === true) {
                    toast.success("Stock transfer successful!", {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                // Clear form data and selected raw material
                setSelectedRawMaterial(null);

                setFormData({
                    storageLocation: "",
                    remark: "",
                    centralStockTransferData: [],
                });

                // Trigger data refresh (assuming getNewStorageLocationData handles data refresh)
                getNewStorageLocationData();
            }
        } catch (error) {
            let errorMessage = '';
            if ( error.response?.status === 406 ) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
            }
            else if ( error.response?.status === 412 ) {
                errorMessage = error?.response?.data?.error;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error
            }
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <CContainer>
            <CCard className='p-3 mb-3'>
                <h6>RAW MATERIALS DAMAGE ENTRIES</h6>
                <hr className='mb-0' />
                <CCardBody>
                    <CForm onSubmit={handleSubmit}>
                    <CRow>
                        <CCol md={12} lg={12}>
                            <CFormLabel className="fw-semibold">
                                Select Storage Location <span className="text-danger">*</span>
                            </CFormLabel>
                            <Select
                                components={animatedComponents}
                                options={storageLocationOptions}
                                placeholder="All Storage Locations"
                                isClearable
                                isDisabled={selectedRawMaterial || formData?.centralStockTransferData?.length > 0}
                                onChange={handleStorageLocationChange}
                                value={storageLocationOptions.find((option) => option.value === formData.storageLocation) || null} // Bind value to state
                            />
                        </CCol>
                    </CRow>
                    <hr />
                    <CRow className='mb-3'>
                        <CCol lg={5}>
                            <CFormLabel className='fw-semibold'>
                                Select Raw Material <span className='text-danger'>*</span>
                            </CFormLabel>
                            <Select
                                isClearable
                                options={rawMaterialOptions}
                                placeholder="Select Raw Materials"
                                value={selectedRawMaterial}
                                onChange={handleRawMaterialChange}
                            />
                        </CCol>
                        <CCol md={2}>
                            <CFormLabel className="fw-semibold">
                                Current Qty <span className='text-danger'>*</span>
                            </CFormLabel>
                            <CFormInput
                                disabled
                                type="text"
                                value={
                                    selectedRawMaterial?.currentStock 
                                        ? `${selectedRawMaterial?.currentStock?.toFixed(2)} ${selectedRawMaterial.unit || ""}`
                                        : ""
                                }
                            />
                        </CCol>
                        <CCol md={2}>
                            <CFormLabel className="fw-semibold">
                                Transfer Damage Qty <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                type="number"
                                min="0"
                                step="any"
                                value={transferQty}
                                onChange={handleTransferQtyChange}
                            />
                        </CCol>
                        <CCol md={3}>
                            <div className='d-flex flex-column'>
                                <CFormLabel className="text-white fw-semibold">.</CFormLabel>
                                <CButton
                                    size="sm"
                                    className="btn btn-circle me-2 w-50"
                                    color="dark" variant="outline"
                                    onClick={handleAddMore}
                                >
                                    + ADD MORE
                                </CButton>
                            </div>
                        </CCol>
                    </CRow>

                    {/* Display Selected Raw Materials */}
                    {formData?.centralStockTransferData?.length > 0 && (
                        <div className="mt-3">
                            <h6 className='text-primary' style={{ textDecoration: 'underline' }}>Selected Raw Materials</h6>
                            {formData?.centralStockTransferData?.map((item, index) => {
                                console.log("Item:", item);  // Log each item to see its structure
                                console.log("selected raw material :", selectedRawMaterial);
                                
                                return (
                                    <CRow className="mb-2 align-items-center" key={index}>
                                        <CCol lg={4}>
                                            <CFormLabel className="fw-semibold text-muted">Raw Material</CFormLabel>
                                            <Select
                                                options={rawMaterials?.map((rm) => ({
                                                        value: rm.rawMaterial._id,
                                                        label: rm.rawMaterial.name,
                                                        unit: rm.rawMaterial.unit,
                                                        currentStock: rm.currentStock,
                                                    })
                                                )}
                                                // value={rawMaterials?.find((option) => option?.rawMaterial._id === item?.rawMaterialId)}
                                                value={item?.rawMaterialId ? { value: item?.rawMaterialId, label: item?.rawMaterialName } : selectedRawMaterialAddMore}

                                                onChange={(selectedOption) => {
                                                    console.log("Selected Options0", selectedOption);
                                                    setSelectedRawMaterialAddMore(selectedOption);
                                                    const updatedData = [...formData.centralStockTransferData];
                                                    updatedData[index] = {
                                                        ...updatedData[index],
                                                        rawMaterialId: selectedOption?.value || "",  // Reset if null
                                                        rawMaterialName: selectedOption?.label || "",
                                                        currentStockQuantity: selectedOption?.currentStock || "",  // Reset if null
                                                        unit: selectedOption?.unit || "",  // Reset if null
                                                        transferQuantity: "",  // Always reset transfer quantity
                                                    };
                                                    setFormData({ ...formData, centralStockTransferData: updatedData });
                                                }}
                                                isClearable
                                                placeholder="Select Raw Material"
                                            />
                                            
                                        </CCol>
                                        <CCol lg={2}>
                                            <CFormLabel className="fw-semibold text-muted">Current Qty</CFormLabel>
                                            <CFormInput
                                                type="text"
                                                value={item?.currentStockQuantity?.toFixed(2)}
                                                readOnly
                                                disabled
                                            />
                                        </CCol>
                                        <CCol lg={2}>
                                            <CFormLabel className="fw-semibold text-muted">Unit</CFormLabel>
                                            <CFormInput
                                                type="text"
                                                value={item.unit}
                                                readOnly
                                                disabled
                                            />
                                        </CCol>
                                        <CCol lg={2}>
                                            <CFormLabel className="fw-semibold text-muted">Transfer Damage Qty</CFormLabel>
                                            <CFormInput
                                                type="number"
                                                min="0"
                                                step="any"
                                                value={item.transferQuantity}
                                                onChange={(e) => {
                                                    const updatedData = [...formData.centralStockTransferData];
                                                    updatedData[index].transferQty = e.target.value;
                                                    setFormData({ ...formData, centralStockTransferData: updatedData });
                                                }}
                                            />
                                        </CCol>
                                        <CCol lg={2}>
                                            <CButton
                                                size="sm"
                                                color="danger"
                                                variant="outline"
                                                className="mt-4"
                                                onClick={() => {
                                                    const updatedData = formData.centralStockTransferData.filter((_, i) => i !== index);
                                                    setFormData({ ...formData, centralStockTransferData: updatedData });
                                                }}
                                            >
                                                Remove
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                );
                            })}

                        </div>
                    )}


                    <hr />
                    <CRow className='mb-4'>
                        <CCol lg={12}>
                            <CFormLabel className='fw-semibold'>
                                Remark <span className='text-danger'>*{" "}</span><span className='fw-normal text-danger' style={{ fontSize: '13px' }}>(Enter Atleast 50 Characters)</span>
                            </CFormLabel>
                            <CFormTextarea
                                rows={2}
                                placeholder="Enter Remark"
                                name="remark"
                                value={formData.remark}
                                onChange={(e) => setFormData({ ...formData, remark: e.target.value })}
                            />
                        </CCol>
                    </CRow>

                    <hr />
                    <CRow className='mt-4'>
                        <CCol>
                            <CButton className='w-25 btn-primary' type="submit">
                                { isLoading ? <Spinner size='sm' /> : "SUBMIT ENTRY" }
                            </CButton>
                        </CCol>
                    </CRow>
                    </CForm>
                </CCardBody>
            </CCard>
        </CContainer>
    );
}

export default RMDamageEntries;
