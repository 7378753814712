import {
  Dashboard,
  Category,
  RawMaterialSubCategory,
  Outlets,
  Vendors,
  Roles,
  RawMaterialMainCategory,
  OutletUsers,
  RawMaterials,
  Products,
  ProductAddOn,
  Procurements,
  ProductsCatalog,
  ProcurementCatalog,
  StockTransfer,
  RmStockReports,
  AddCoupon,
  CouponCard,
  AddStory,
  ViewAdvertisement,
  AddAdvertisement,
  Level,
  LevelRewards,
  ViewLevelRewards,
  Add,
  CustomerReport,
  OrdersReport,
  KioskUserReport,
  ProductSalesReport,
  AddonWiseReport,
  CustomerSupportMgt,
  DaySummaryReport,
  AssetType,
  Assets,
  StorageLocation,
  AssetsStockTransaction,
  AssetStockTransactionReport,
  UserCouponReport,
  StockTransactionReport,
  ProductIngredients,
  RMReorderLevelReport,
  KioskOutletUser,
  CentralStTransReport,
  CancellationRequests,
  Settings,
  VersionControl,
  StorageLocationStTransfer,
  RMDamageEntries,
  ItemWiseReport
} from "./screens";

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  {
    path: "/raw-materials-management",
    name: "Raw Material Management",
    exact: true,
  },
  { path: "/material-mgt", name: "Material Management", exact: true },
  {
    path: "/material-mgt/rm-main-category",
    name: "Raw Material Main Category",
    element: RawMaterialMainCategory,
  },
  {
    path: "/material-mgt/rm-sub-category",
    name: "Raw Material Sub Category",
    element: RawMaterialSubCategory,
  },
  {
    path: "/material-mgt/rawmaterials",
    name: "Raw Materials",
    element: RawMaterials,
  },

  { path: "/masters/vendors", name: "Vendors", element: Vendors },
  { path: "/masters", name: "Masters", exact: true },
  { path: "/masters/admin-roles", name: "Admin Roles", element: Roles },
  { path: "/customer-support", name: "Customer Support Management", element: CustomerSupportMgt },
  { path: "/cancellation-request", name: "Cancellation Requests", element: CancellationRequests },

  { path: "/outlet-management", name: "Outlet Management", exact: true },
  { path: "/outlet-management/outlets", name: "Outlets", element: Outlets },
  {
    path: "/outlet-management/outletusers",
    name: "Outlet Users",
    element: OutletUsers,
  },
  {
    path: "/outlet-management/kisok-outlet-user",
    name: "Kiosk Outlet Users",
    element: KioskOutletUser,
  },

  { path: "/menu", name: "Menu", exact: true },
  {
    path: "/menu/categories",
    name: "Category",
    element: Category,
  },
  {
    path: "/menu/add-menu",
    name: "Add Menu",
    element: Products,
  },
  {
    path: "/menu/menu-catalog",
    name: "Menu Catalog",
    element: ProductsCatalog,
  },
  {
    path: "/menu/menu-addon",
    name: "Menu Addon",
    element: ProductAddOn,
  },
  {
    path: "/menu/menu-ingredients",
    name: "Menu Ingredients",
    element: ProductIngredients,
  },
  { path: "/reports", name: "Reports", exact: true },
  {
    path: "/reports/customer-report",
    name: "Customer Reports",
    element: CustomerReport,
  },
  {
    path: "/reports/order-report",
    name: "Orders Reports",
    element: OrdersReport,
  },
  {
    path: "/reports/item-wise-sales-report",
    name: "Item Wise Sales Reports",
    element: ItemWiseReport,
  },
  {
    path: "/reports/kiosk-user-report",
    name: "Kiosk User Reports",
    element: KioskUserReport,
  },
  {
    path: "/reports/product-report",
    name: "Product Sales Reports",
    element: ProductSalesReport,
  },
  {
    path: "/reports/addon-report",
    name: "Addon Wise Reports",
    element: AddonWiseReport,
  },
  {
    path: "/reports/day-summary-report",
    name: "Day Summary Reports",
    element: DaySummaryReport,
  },
  {
    path: "/reports/month-summary-report",
    name: "Month Summary Report",
    element: DaySummaryReport,
  },
  {
    path: "/reports/user-coupon-report",
    name: "User Coupon Reports",
    element: UserCouponReport,
  },
  {
    path: "/reports/rm-reorder-level-report",
    name: "RM Reorder Level Report",
    element: RMReorderLevelReport,
  },
  {
    path: "/transaction/stock-transaction-report",
    name: "Outlet Stock Transations",
    element: StockTransactionReport,
  },
  {
    path: "/masters/asset-type",
    name: "Asset Type",
    element: AssetType,
  },
  { path: "/asset-management", name: "Asset Management", exact: true },
  {
    path: "/asset-management/assets",
    name: "Add New Asset",
    element: Assets,
  },
  {
    path: "/masters/storage-type",
    name: "Storage Location",
    element: StorageLocation,
  },
  {
    path: "/asset-management/assets-stock-transaction",
    name: "Asset Stock Transaction",
    element: AssetsStockTransaction,
  },
  {
    path: "/transaction/asset-stock-transaction-report",
    name: "Asset Stock Transaction Report",
    element: AssetStockTransactionReport,
  },
  { path: "/purchase", name: "Purchase", exact: true },
  {
    path: "/purchase/add-purchase",
    name: "Add Purchase",
    element: Procurements,
  },
  {
    path: "/purchase/purchase-list",
    name: "Purchase List",
    element: ProcurementCatalog,
  },
  {
    path: "/purchase/storage-location-stock-transfer",
    name: "Storage Location Stock Transfer",
    element: StorageLocationStTransfer,
  },
  {
    path: "/purchase/stock-transfer",
    name: "Stock Transfer",
    element: StockTransfer,
  },
  {
    path: "/purchase/rm-stock-reports",
    name: "RM Stock Reports",
    element: RmStockReports,
  },
  {
    path: "/purchase/rm-damage-entries",
    name: "RM Damage Entries",
    element: RMDamageEntries,
  },
  { path: "/transaction", name: "Transactions", exact: true },
  {
    path: "/transaction/central-stock-transaction-report",
    name: "Central Stock Transaction Report",
    element: CentralStTransReport,
  },
  { path: "/coupons", name: "Coupons", exact: true },
  {
    path: "/coupons/add",
    name: "Add",
    element: AddCoupon,
  },
  {
    path: "/coupons/view",
    name: "View",
    element: CouponCard,
  },

  { path: "/content-management", name: "Content-Management", exact: true },
  {
    path: "/content-management/stories",
    name: "Stories",
    element: AddStory,
  },
  {
    path: "/content-management/view-advertisement",
    name: "View Advertisement",
    element: ViewAdvertisement,
  },
  {
    path: "/content-management/add-advertisement",
    name: "Add Advertisement",
    element: AddAdvertisement,
  },
  { path: "/user-levels", name: "User Levels", exact: true },
  {
    path: "/user-levels/level",
    name: "Level",
    element: Level,
  },
  {
    path: "/user-levels/add-reward",
    name: "Level Rewards / Add",
    element: LevelRewards,
  },
  {
    path: "/user-levels/view",
    name: "Level Rewards / View",
    element: ViewLevelRewards,
  },
  {
    path: "/user-coupon-allocation",
    name: "User Coupon Allocation",
    element: Add,
  },

  { path: "/system-preference", name: "System Prefernce", exact: true },
  {
    path: "/system-preference/settings",
    name: "Settings",
    element: Settings
  },
  {
    path: "/system-preference/version-control",
    name: "Version Control",
    element: VersionControl
  },

  { path: "/system-log", name: "System Logs", exact: true },

];

export default routes;
