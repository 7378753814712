import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { DataTableComponent, PrimaryButton } from '../../components';
import axios from '../../api/axios';
import Select from 'react-select';
import { toast } from 'react-toastify';

const ProductIngredients = () => {

    const accessToken = localStorage.getItem("authToken");
    const [addNewOpenModal, setAddNewOpenModal] = useState(false);
    const [productsList, setProductsList] = useState([]);
    const [filteredProductList, setFilteredProductList] = useState([]);
    const [rawMaterialList, setRawMaterialList] = useState([]);
    const [inputData, setInputData] = useState({
        product: '',
        rawMaterials: [{ rawMaterial: '', quantity: '', unit: "" }],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
    const [isUdpateBtnLoading, setIsUdpateBtnLoading] = useState(false);
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [editedData, setEditedData] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [ingredientsList, setIngredientsList] = useState([]);
    const [productIngredientId, setProductIngredientId] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
    const [categoryList, setCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);


    const columns = [
        {
            name: "Sr.No",
            width: "80px",
            cell: (row, index) => (
                <div className="fs-7">
                    {row.serialNumber ? row.serialNumber : "----"}
                </div>
            ),
        },
        {
            name: "Menu Name & Ingredients",
            center: "true",
            width: "900px",
            cell: (row) => (
                <div className='w-100'>
                    <div className="fs-5 fw-bold mt-2 text-center" style={{ color: '#0E4961' }}>
                        {row?.product?.name ? row?.product?.name : "----"}
                    </div>

                    {/* { row?.rawMaterials && row?.rawMaterial?.map((rm) => ( */}
                    <CTable striped bordered className="mt-2" style={{ width: "100%" }}>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell className="text-center">Sr. No</CTableHeaderCell>
                                <CTableHeaderCell className="text-center">Raw Material</CTableHeaderCell>
                                <CTableHeaderCell className="text-center">Quantity</CTableHeaderCell>
                                <CTableHeaderCell className="text-center">Unit</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {row?.rawMaterials && row?.rawMaterials?.map((rm, index) => (
                                <CTableRow>
                                    <CTableDataCell className="text-center" style={{ width: '100px' }}> {index + 1} </CTableDataCell>
                                    <CTableDataCell className="text-center fw-semibold text-primary" style={{ width: '450px' }}> {rm?.rawMaterial?.name} </CTableDataCell>
                                    <CTableDataCell className="text-center" style={{ width: '180px' }}> {rm?.quantity} </CTableDataCell>
                                    <CTableDataCell className="text-center" style={{ width: '180px' }}> {rm?.rawMaterial?.unit} </CTableDataCell>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                    {/* ) )  } */}
                </div>
            ),
        },
        {
            name: "ACTIONS",
            center: "true",
            cell: (row) => (
                <div className="d-flex justify-content-around align-items-center">
                    {/* <a
                  href="#"
                  className="text-info ms-1 me-1"
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  onClick={() => handleOpenViewModal(row)}
                >
                  <FaEye /> View
                </a>{" "} */}
                    <a
                        href="#"
                        className="text-primary ms-1 me-1 "
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        onClick={() => handleEditModal(row)}
                    >
                        <FaPencilAlt /> Edit
                    </a>{" "}
                    |
                    <a
                        href="#"
                        className="text-danger ms-1 "
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        onClick={() => handleDeleteModal(row)}
                    >
                        <FaTrash /> Delete
                    </a>
                </div>
            ),
            minWidth: "100px",
        },
    ];

    const updatedColumns = columns.map((column) => ({
        ...column,
        name: (
            <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
        ),
    }));

    const getIngredients = async (catId) => {
        setIsLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("recipe", { headers });
            response?.data?.data?.recieps?.forEach((item, index) => {
                item.serialNumber = index + 1;
            });

            if (catId === "" || catId === undefined) {
                setFilteredData(response?.data?.data?.recieps);
                setIngredientsList(response?.data?.data?.recieps);
            } else {
                let filteredRecipes = response?.data?.data?.recieps?.filter((rec) => rec?.product?.category === catId);
                setFilteredData(filteredRecipes);
                setIngredientsList(response?.data?.data?.recieps);
            }

            // if (response?.data?.status === true) {
            //     toast.success("Data Fetched Successfully", {
            //       position: "top-right",
            //       autoClose: 3000,
            //       theme: "colored",
            //     });
            // }

            // let filteredRecipes = response?.data?.data?.recieps?.filter((rec) => rec?.product?.category  === catId);
            // console.log("Filtered Recipes :", filteredRecipes);

            // setIngredientsList(response?.data?.data?.recieps);
            // setFilteredData(response?.data?.data?.recieps);
            // setFilteredData(filteredRecipes);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    }

    const getProductsList = async (id) => {
        console.log("IDDDDDD :", id);

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                console.log("Product Response :", response?.data?.data?.products);
                let filterPro = response?.data?.data?.products?.filter((cat) => cat?.category?._id === id);
                console.log("filter products :", filterPro);
                setFilteredProductList(filterPro);
                // setProductsList(response?.data?.data?.products);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getProductsListForEdit = async (id) => {

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                console.log("Product Response :", response?.data?.data?.products);
                // let filterPro = response?.data?.data?.products?.filter((cat) => cat?.category?._id === id);
                // setFilteredProductList(filterPro);
                setProductsList(response?.data?.data?.products);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getRawMaterials = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("raw-material/in-stock", { headers });

            if (response?.data?.status === true) {
                response?.data?.data?.rawMaterials.forEach((item, index) => {
                    item.serialNumber = index + 1;
                });
                console.log("Response Of Raw Materials :", response?.data?.data);

                setRawMaterialList(response?.data?.data?.rawMaterials);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleAddNewOpenModal = () => {
        setAddNewOpenModal(true);
    }

    // Update product or raw materials
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRawMaterialChange = (index, field, value) => {
        const updatedRawMaterials = [...inputData.rawMaterials];

        if (field === 'rawMaterial') {
            const selectedRawMaterial = rawMaterialList.find((rm) => rm._id === value);
            updatedRawMaterials[index].rawMaterial = value;
            updatedRawMaterials[index].unit = selectedRawMaterial ? selectedRawMaterial.unit : '';
        } else {
            updatedRawMaterials[index][field] = value;
        }
        setInputData((prevData) => ({
            ...prevData,
            rawMaterials: updatedRawMaterials,
        }));
    };


    const handleEditRawMaterialChange = (index, field, value) => {
        const updatedRawMaterials = [...editedData.rawMaterials];

        if (field === 'rawMaterial') {
            const selectedRawMaterial = rawMaterialList.find((rm) => rm._id === value);
            updatedRawMaterials[index].rawMaterial = value;
            updatedRawMaterials[index].unit = selectedRawMaterial ? selectedRawMaterial.unit : '';
        } else {
            updatedRawMaterials[index][field] = value;
        }

        // Update state
        setEditedData((prevData) => ({
            ...prevData,
            rawMaterials: updatedRawMaterials,
        }));
    };


    // const handleEditRawMaterialChange = (index, field, value) => {
    //     const updatedRawMaterials = [...editedData.rawMaterials];
    //     updatedRawMaterials[index][field] = value;
    //     setEditedData((prevData) => ({
    //         ...prevData,
    //         rawMaterials: updatedRawMaterials,
    //     }));
    // };

    // Add a new raw material entry
    const addRawMaterialEntry = () => {
        setInputData((prevData) => ({
            ...prevData,
            rawMaterials: [...prevData.rawMaterials, { rawMaterial: '', quantity: '' }],
        }));
    };

    const editRawMaterialEntry = () => {
        setEditedData((prevData) => ({
            ...prevData,
            rawMaterials: [...prevData.rawMaterials, { rawMaterial: '', quantity: '', unit: "" }],
        }));
    };

    // Remove a raw material entry
    const removeRawMaterialEntry = (index) => {
        setInputData((prevData) => ({
            ...prevData,
            rawMaterials: prevData.rawMaterials.filter((_, i) => i !== index),
        }));
    };

    const removeEditRawMaterialEntry = (index) => {
        setEditedData((prevData) => ({
            ...prevData,
            rawMaterials: prevData.rawMaterials.filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsAddBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.post("recipe", inputData, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getIngredients(categoryId);
                setInputData({
                    product: '',
                    rawMaterials: [{ rawMaterial: '', quantity: '' }],
                });
                setAddNewOpenModal(false);
            }
        } catch (error) {
            let errorMessage = "";
            if (error.response?.status === 404) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
            } else if (error.response?.status === 400) {
                errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
            } else if (error.response?.status === 406) {
                errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
            } else if (error.response?.status === 412) {
                errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error;
            }
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setIsAddBtnLoading(false);
        }
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setIsUdpateBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.put("recipe", editedData, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                // getAddonProductsList();
                getIngredients(categoryId);
                setOpenEditModal(false);
            }
        } catch (error) {
            let errorMessage = "";
            if (error.response?.status === 404) {
                errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
            } else if (error.response?.status === 400) {
                errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
            } else if (error.response?.status === 406) {
                errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
            } else if (error.response?.status === 412) {
                errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error;
            }
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setIsUdpateBtnLoading(false);
        }
    }

    // const handleEditModal = (data) => {
    //     console.log("data", data);

    //     setEditedData({
    //         _id: data?._id,
    //         product: data?.product?._id,
    //         rawMaterials: data?.rawMaterials?.map(item => ({
    //             _id: item?._id,
    //             rawMaterial: item?.rawMaterial?._id, // Contains the object with details about the raw material
    //             quantity: item?.quantity,
    //             unit: item?.unit,
    //             isOptional: item?.isOptional // Include any additional fields if necessary
    //         })) || [{ rawMaterial: '', quantity: '', unit: "" }] // Default in case rawMaterials is undefined or empty
    //     })
    //     setOpenEditModal(true);
    // }

    const handleEditModal = (data) => {
        const mappedRawMaterials = data?.rawMaterials?.map(item => ({
            _id: item?._id,
            rawMaterial: item?.rawMaterial?._id,
            quantity: item?.quantity || '',
            unit: item?.rawMaterial?.unit || '',
            isOptional: item?.isOptional || false
        })) || [{ rawMaterial: '', quantity: '', unit: '' }];
        setEditedData({
            _id: data?._id,
            product: data?.product?._id || '',
            rawMaterials: mappedRawMaterials,
        });

        setOpenEditModal(true);
    };

    const handleDeleteModal = (data) => {
        const ingredientId = data?._id;
        console.log("Ingredient Id :", data);
        setProductIngredientId(ingredientId);
        setOpenDeleteModal(true);
    }

    const handleDeleteProductIngredient = async (e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const data = {
                _id: productIngredientId,
            };
            const response = await axios.delete("recipe", { data, headers })
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getIngredients();
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = '';
                // error.response.data.error
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    }

    const getCategoryList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });

            if (response?.data?.status === true) {
                response?.data?.data?.categories.forEach((item, index) => {
                    item.serialNumber = index + 1;
                });
                console.log("Category List :", response?.data?.data?.categories);

                setCategoryList(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
    };

    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };

    // Function to get paginated data
    const getPaginatedData = () => {
        const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
        const endIndex = startIndex + pagination.rowsPerPage;
        return filteredData.slice(startIndex, endIndex);
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleSubmitData = async (e) => {
        e.preventDefault();
        setIsClicked(true);
        setSubmitBtnLoading(true);
        await getIngredients(categoryId);
        toast.success("Data Fetched Successfully", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
        });
        setSubmitBtnLoading(false);
    }

    useEffect(() => {
        // getProductsList();
        getRawMaterials();
        getProductsListForEdit();
        // getIngredients();
        getCategoryList();
    }, []);

    useEffect(() => {
        const result = ingredientsList?.filter((item) => {
            return (
                item?.product?.name?.toLowerCase().match(searchText.toLocaleLowerCase())
            )
        });
        setFilteredData(result)
    }, [searchText]);

    return (
        <div className='container'>
            <CRow className="align-item-center justify-content-between mb-4">
                <CCol md="3" lg="3">
                    <CFormLabel className="fw-semibold">Select Category</CFormLabel>
                    <CFormSelect
                        value={categoryId}
                        onChange={(e) => { setCategoryId(e.target.value) }}
                    >
                        <option value="">Select Category</option>
                        {categoryList.map((rm) => (
                            <option key={rm._id} value={rm._id}>
                                {rm.name}
                            </option>
                        ))}
                    </CFormSelect>
                </CCol>
                <CCol>
                    <div className='d-flex w-50' style={{ flexDirection: 'column' }}>
                        <CFormLabel className='text-white'>.</CFormLabel>
                        <CButton className='btn rounded-pill w-100' onClick={handleSubmitData}>
                            {submitBtnLoading ? <CSpinner size='sm' /> : "Submit"}
                        </CButton>
                    </div>
                </CCol>
                <CCol style={{ display: 'flex', justifyContent: 'end' }} md={4} lg={4}>
                    <div className="text-end d-flex" style={{ flexDirection: 'column' }}>
                        <CFormLabel className="text-white">.</CFormLabel>
                        <button
                            className="btn btn-warning w-100"
                            onClick={handleAddNewOpenModal}
                            style={{ borderRadius: "18px" }}
                        >

                            ADD NEW MENU INGREDIENTS <FaPlus style={{ marginTop: "-4px" }} size={15} />
                        </button>
                    </div>
                </CCol>
            </CRow>

            {isClicked ? (
                <div>
                    <DataTableComponent
                        columns={updatedColumns}
                        data={getPaginatedData()}
                        title="MENU INGREDIENTS LIST"
                        searchText={searchText}
                        onChange={handleSearchChange}
                        pagination
                        totalRows={filteredData?.length}
                        paginationTotalRows={filteredData?.length} // Total number of items
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        onChangePage={handlePageChange}
                        paginationServer={false} // Set to false for client-side pagination
                    />
                </div>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "300px" }}
                >
                    <h5 className="text-muted">
                        Please Select Category and Click On Submit To Get The Data
                    </h5>
                </div>
            )}


            {addNewOpenModal ? (
                <CModal
                    size="lg"
                    alignment="center"
                    visible={addNewOpenModal}
                    onClose={() => setAddNewOpenModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle className="fs-6">ADD PRODUCT INGREDIENTS</CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                        <CModalBody>
                            <CRow>
                                <CCol md="5">
                                    <CFormLabel className="fw-semibold">Select Category</CFormLabel>
                                    <CFormSelect
                                        id='category'
                                        name='category'
                                        value={categoryId}
                                        onChange={(e) => { getProductsList(e.target.value); setCategoryId(e.target.value) }}
                                    >
                                        <option>Select Category</option>
                                        {categoryList.map((rm) => (
                                            <option key={rm._id} value={rm._id}>
                                                {rm.name}
                                            </option>
                                        ))}
                                    </CFormSelect>
                                </CCol>
                                <CCol md="7">
                                    <div className="mb-3">
                                        <CFormLabel className="fw-semibold">
                                            Select Menu <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <Select
                                            options={filteredProductList?.map((rm) => ({
                                                value: rm?._id,
                                                label: rm?.name,
                                            }))}
                                            value={
                                                inputData.product ? { value: inputData?.product, label: filteredProductList?.find(rm => rm._id === inputData?.product)?.name }
                                                    : null
                                            }
                                            onChange={(selectedOption) => {
                                                handleChange({
                                                    target: {
                                                        name: 'product',
                                                        value: selectedOption ? selectedOption.value : '',
                                                    },
                                                });
                                            }}
                                            classNamePrefix="react-select"
                                            isClearable
                                            placeholder="Select Menu"
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CFormLabel className="fw-semibold">Select Raw Materials <span className="text-danger">*</span></CFormLabel>
                                    {inputData.rawMaterials.map((entry, index) => (
                                        <CRow key={index} className="mb-3 align-items-center">
                                            <CCol md="4">
                                                <Select
                                                    options={rawMaterialList.map((rm) => ({
                                                        value: rm._id,
                                                        label: `${rm.name} ${rm.inStock === false ? '(Out of stock)' : ''}`,

                                                    }))}
                                                    value={
                                                        entry.rawMaterial
                                                            ? {
                                                                value: entry.rawMaterial,
                                                                label: rawMaterialList.find((rm) => rm._id === entry.rawMaterial)?.name,
                                                            }
                                                            : null
                                                    }
                                                    onChange={(selectedOption) =>
                                                        handleRawMaterialChange(index, 'rawMaterial', selectedOption ? selectedOption.value : '')
                                                    }
                                                    classNamePrefix="react-select"
                                                    isClearable
                                                    placeholder="Select Raw Material"
                                                />

                                            </CCol>
                                            <CCol md="3">
                                                <CFormInput
                                                    type="number"
                                                    placeholder="Quantity"
                                                    value={entry.quantity}
                                                    onChange={(e) =>
                                                        handleRawMaterialChange(index, 'quantity', e.target.value)
                                                    }
                                                />
                                            </CCol>
                                            <CCol md="3">
                                                <CFormInput
                                                    type="text"
                                                    disabled
                                                    placeholder="Unit"
                                                    value={entry.unit}
                                                />
                                            </CCol>
                                            <CCol xs="auto" md="2">
                                                <button
                                                    style={{ borderRadius: '18px' }}
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => removeRawMaterialEntry(index)}
                                                >
                                                    Remove
                                                </button>
                                            </CCol>
                                        </CRow>
                                    ))}
                                    <button type="button" style={{ borderRadius: '18px' }} className="btn btn-primary mt-2" onClick={addRawMaterialEntry}>
                                        <FaPlus /> Add Another Raw Material
                                    </button>
                                </CCol>
                            </CRow>
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton
                                // isDisable={btnDisable()}
                                title="ADD PRODUCT INGREDIENTS"
                                isLoading={isAddBtnLoading}
                            />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null}

            {openEditModal && (
                <CModal
                    size="lg"
                    alignment="center"
                    visible={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle className="fs-6">EDIT PRODUCT INGREDIENTS</CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleEditSubmit}>
                        <CModalBody>
                            <CRow>
                                <CCol>
                                    <div className="mb-3">
                                        <CFormLabel className="fw-semibold">
                                            Select Product <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <Select
                                            options={productsList?.map((product) => ({
                                                value: product._id,
                                                label: product.name,
                                            }))}
                                            value={
                                                editedData.product
                                                    ? {
                                                        value: editedData.product,
                                                        label: productsList.find(
                                                            (product) => product._id === editedData.product
                                                        )?.name
                                                    }
                                                    : null
                                            }
                                            onChange={(selectedOption) => {
                                                handleEditChange({
                                                    target: {
                                                        name: 'product',
                                                        value: selectedOption ? selectedOption.value : '',
                                                    },
                                                });
                                            }}
                                            classNamePrefix="react-select"
                                            isClearable
                                            placeholder="Select Product"
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CFormLabel className="fw-semibold">
                                        Select Raw Materials <span className="text-danger">*</span>
                                    </CFormLabel>
                                    {editedData?.rawMaterials?.map((entry, index) => (
                                        <CRow key={index} className="mb-3 align-items-center">
                                            <CCol>
                                                <Select
                                                    options={rawMaterialList?.map((rm) => ({
                                                        value: rm._id,
                                                        label: `${rm.name} ${rm?.inStock === false ? '(Out of stock)' : ''}`,
                                                        isOutOfStock: rm?.inStock === false, // Adding a custom flag for out of stock items
                                                    }))}
                                                    value={
                                                        entry.rawMaterial
                                                            ? {
                                                                value: entry.rawMaterial,
                                                                label: rawMaterialList.find((rm) => rm._id === entry.rawMaterial)?.name,
                                                            }
                                                            : null
                                                    }
                                                    onChange={(selectedOption) =>
                                                        handleEditRawMaterialChange(index, 'rawMaterial', selectedOption ? selectedOption.value : '')
                                                    }
                                                    classNamePrefix="react-select"
                                                    isClearable
                                                    placeholder="Select Raw Material"
                                                />
                                            </CCol>

                                            <CCol>
                                                <CFormInput
                                                    type="number"
                                                    placeholder="Quantity"
                                                    value={entry.quantity || ''}
                                                    onChange={(e) =>
                                                        handleEditRawMaterialChange(index, 'quantity', e.target.value)
                                                    }
                                                />
                                            </CCol>
                                            <CCol>
                                                <CFormInput
                                                    type="text"
                                                    disabled
                                                    placeholder="Unit"
                                                    value={entry.unit || ''}
                                                />
                                            </CCol>
                                            <CCol xs="auto">
                                                <button
                                                    style={{ borderRadius: '18px' }}
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => removeEditRawMaterialEntry(index)}
                                                >
                                                    Remove
                                                </button>
                                            </CCol>
                                        </CRow>
                                    ))}
                                    <button
                                        type="button"
                                        style={{ borderRadius: '18px' }}
                                        className="btn btn-primary mt-2"
                                        onClick={editRawMaterialEntry}
                                    >
                                        Add Another Raw Material
                                    </button>
                                </CCol>
                            </CRow>
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton
                                title="UPDATE PRODUCT INGREDIENT"
                                isLoading={isUdpateBtnLoading}
                            />
                        </CModalFooter>
                    </CForm>
                </CModal>
            )}

            {openDeleteModal ? (
                <CModal
                    alignment="center"
                    visible={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                            DELETE PRODUCT INGREDIENTS
                        </CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleDeleteProductIngredient}>
                        <CModalBody>
                            Are you sure you want to delete this product ingredients ?
                        </CModalBody>
                        <CModalFooter>
                            <CButton
                                color="warning"
                                onClick={() => setOpenDeleteModal(false)}
                                style={{
                                    borderRadius: "18px",
                                }}
                            >
                                CLOSE
                            </CButton>

                            <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null}
        </div>
    )
}

export default ProductIngredients