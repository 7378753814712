import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CBadge,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { DataTableComponent, PrimaryButton } from "../../components";
import { FaPlus, FaEye, FaTrash, FaPencilAlt } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";

const OutletUsers = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    outlet: "",
    name: "",
    role: "",
    mobile: "",
    email: "",
    password: "",
  });
  const [outletUsers, setOutletUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [outletUserId, setOutletUserId] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [outletUserDetail, setOutletUserDetail] = useState({});
  const [validationErrors, setValidationErrors] = useState({
    outlet: "",
    name: "",
    role: "",
    mobile: "",
    email: "",
    password: "",
  });
  const [editValidationErrors, setEditValidationErrors] = useState({
    outlet: "",
    name: "",
    role: "",
    mobile: "",
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [addNewOpenModal, setAddNewOpenModal] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>
      ),
      width: "70px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row.name ? row.name : "--------"}</div>
      ),
      width: "180px",
    },
    {
      name: "CONTACT DETAILS",
      selector: (row) => row.email,
      cell: (row) => (
        <div>
          <span className="fs-7 text-primary" style={{ textDecoration: "none" }}>
            {row.email ? row.email : "--------"}
          </span>{" "}
          <br />
          <span className="fs-7 text-danger" style={{ textDecoration: "none" }}>
            {row.mobile ? row.mobile : "--------"}
          </span>
        </div>
      ),
      width: "200px",
    },
    {
      name: "OUTLET",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row?.outlet?.name ? row?.outlet?.name : "--------"}</div>
      ),
      width: "180px",
    },
    {
      name: "ID NUMBER",
      selector: (row) => row.idNumber,
      cell: (row) => (
        <div className="fs-7">{row.idNumber ? row.idNumber : "--------"}</div>
      ),
      width: "130px",
    },
    {
      name: "ROLE",
      selector: (row) => row.role,
      cell: (row) => (
        <div className="fs-7">{row.role ? row.role : "--------"}</div>
      ),
      width: "130px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="fs-7">
          {row.status ? (
            <CBadge color={row.status === "ACTIVE" ? "success" : "danger"}>
              {row.status}
            </CBadge>
          ) : (
            "--------"
          )}
        </div>
      ),
      width: "120px",
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        // <div className="d-flex justify-content-around align-items-center">
        //   <a
        //     href="#"
        //     className="text-info me-2"
        //     style={{ cursor: "pointer", textDecoration: "none" }}
        //     onClick={() => handleOpenViewModal(row)}
        //   >
        //     <FaEye /> View
        //   </a>{" "}
        //   |
        //   <a
        //     href="#"
        //     className="text-primary me-2 ms-2"
        //     style={{ cursor: "pointer", textDecoration: "none" }}
        //     onClick={() => handleEditModal(row)}
        //   >
        //     <FaPencilAlt /> Edit
        //   </a>{" "}
        //   |
        //   <a
        //     href="#"
        //     className="text-danger ms-2"
        //     style={{ cursor: "pointer", textDecoration: "none" }}
        //     onClick={() => handleDeleteModal(row)}
        //   >
        //     <FaTrash /> Delete
        //   </a>
        // </div>

        <Dropdown>
          <Dropdown.Toggle variant="secondary" size="sm">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleOpenViewModal(row)} className="text-success">
              <FaEye className="me-2" /> View
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleEditModal(row)} className="text-primary">
              <FaPencilAlt className="me-2" /> Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDeleteModal(row)} className="text-danger">
              <FaTrash className="me-2" /> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleAddNewOpenModal = () => {
    setAddNewOpenModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({ ...prevState, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.outlet) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        outlet: "Outlet is required.",
      }));
      return;
    }

    if (!inputData.name) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required.",
      }));
      return;
    }

    if (!inputData.role) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        role: "Role is required.",
      }));
      return;
    }
    if (!inputData.mobile) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile is required.",
      }));
      return;
    }

    if (inputData.mobile.length !== 10) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile Number Must be 10 digits.",
      }));
      return;
    }
    if (!/^\d+$/u.test(inputData.mobile)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Invalid mobile number. Please enter only numeric characters.",
      }));
      return;
    }

    if (!inputData.email) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: "email is required.",
      }));
      return;
    }
    if (!inputData.password) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: "password is required.",
      }));
      return;
    }

    if (Object.values(validationErrors).some((error) => error !== "")) {
      return;
    }
    setIsLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("outlet/user", inputData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getOutletUsers();

        setInputData({
          outlet: "",
          name: "",
          role: "",
          mobile: "",
          email: "",
          password: "",
        });
        setAddNewOpenModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getOutletUsers = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet/user", { headers });

      if (response?.data?.status === true) {
        response?.data?.data?.outletUsers.forEach((item, index) => {
          item.serialNumber = index + 1;
        });

        setOutletUsers(response?.data?.data?.outletUsers);
        setFilteredData(response?.data?.data?.outletUsers);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setEditedData({
      _id: data._id,
      outlet: data.outlet._id,
      name: data.name,
      role: data.role,
      mobile: data.mobile,
      email: data.email,
      password: data.password,
      status: data.status,
    });
    setOutletUserId(data._id);
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({ ...prevState, [name]: value }));
    setEditValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (!editedData.outlet) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        outlet: "Outlet is required.",
      }));
      return;
    }

    if (!editedData.name) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required.",
      }));
      return;
    }

    if (!editedData.role) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        role: "Role is required.",
      }));
      return;
    }
    if (!editedData.mobile) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile is required.",
      }));
      return;
    }

    if (editedData.mobile.length !== 10) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile Number Must be 10 digits.",
      }));
      return;
    }
    if (!/^\d+$/u.test(editedData.mobile)) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Invalid mobile number. Please enter only numeric characters.",
      }));
      return;
    }

    if (!editedData.email) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required.",
      }));
      return;
    }
    if (!editedData.password) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password is required.",
      }));
      return;
    }
    if (!editedData.status) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        status: "Status is required.",
      }));
      return;
    }

    if (Object.values(editValidationErrors).some((error) => error !== "")) {
      return;
    }

    setIsEditBtnLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put("outlet/user", editedData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getOutletUsers();
        setOpenEditModal(false);
        getSingleOutletUser(outletUserId);
        setOpenViewModal(true);
        setOutletUserId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setOutletUserId(data?._id);
  };

  const handleDeleteOutletUser = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: outletUserId,
      };
      const response = await axios.delete(`outlet/user`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getOutletUsers();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleOpenViewModal = (data) => {
    getSingleOutletUser(data?._id);
    setOpenViewModal(true);
  };

  const getSingleOutletUser = async (userId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`outlet/user?_id=${userId}`, {
        headers,
      });

      if (response?.data?.status === true) {
        setOutletUserDetail(response?.data?.data?.outletUsers);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        setOutlets(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const TableWithTitle = ({ title, data }) => (
    <div style={{ flex: 1, marginRight: "20px", width: "450px" }}>
      <h5
        className="fs-6 text-center fw-semibold"
        style={{ textDecoration: "underline" }}
      >
        {title}
      </h5>
      <table className="table table-striped table-bordered">
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <th className="fw-semibold " style={{ fontSize: "14px" }}>
                {item.label}
              </th>
              <td className="fw-semibold" style={{ fontSize: "13px" }}>
                {item.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
  
  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
  
  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData?.slice(startIndex, endIndex);
  };

  const disableButton =
    !inputData.outlet ||
    !inputData.name?.trim() ||
    !inputData.role ||
    !inputData.mobile ||
    inputData.mobile.length < 10 ||
    !inputData.email?.trim() ||
    !inputData.password?.trim();

  const editButtonDisable =
    !editedData ||
    !editedData.outlet ||
    !editedData.name?.trim() ||
    !editedData.role ||
    !editedData.mobile ||
    editedData.mobile.length < 10 ||
    !editedData.email?.trim() ||
    !editedData.status;

  useEffect(() => {
    getOutlets();
    getOutletUsers();
  }, []);

  function escapeRegExp(text) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  useEffect(() => {
    const escapedSearchText = escapeRegExp(searchText);
    const regex = new RegExp(escapedSearchText, "i");

    const result = outletUsers.filter(
      (item) =>
        item.name.match(regex) ||
        item.email.match(regex) ||
        item.mobile.match(regex) ||
        item.idNumber.match(regex) ||
        item.role.match(regex) ||
        item.status.match(regex)
    );

    setFilteredData(result);
  }, [searchText]);

  return (
    <CContainer>
      <div className="mb-3 text-end">
        <button
          className="btn btn-warning"
          onClick={handleAddNewOpenModal}
          style={{ borderRadius: "18px" }}
        >
          ADD NEW OUTLET USER <FaPlus style={{ marginTop: "-4px" }} size={15} />
        </button>
      </div>

      <div>
        <DataTableComponent
          columns={updatedColumns}
          title="OUTLET USERS LIST"
          // data={filteredData}
          data={getPaginatedData()} // Use the paginated data here
          searchText={searchText}
          onChange={handleSearchChange}
          striped
          itemsPerPage={10}
          pagination
          totalRows={filteredData?.length}
          paginationTotalRows={filteredData?.length} // Total number of items
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationServer={false} // Set to false for client-side pagination
        />
      </div>

      {openEditModal ? (
        <CModal
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader style={{ backgroundColor: "#f8f8f8" }}>
            <CModalTitle
              id="VerticallyCenteredExample"
              className="fs-6 fw-semibold"
            >
              EDIT OUTLET USER
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Outlet <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="outlet"
                      className="mb-3"
                      value={editedData.outlet}
                    >
                      <option>Select Outlet</option>
                      {outlets.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.outlet}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Select Role <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="role"
                      className="mb-3"
                      value={editedData.role}
                    >
                      <option>Select Role</option>
                      <option value="MANAGER">MANAGER</option>
                      <option value="CASHIER">CASHIER</option>
                      <option value="CAFE-MANAGER">CAFE-MANAGER</option>

                    </CFormSelect>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.role}
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={editedData.name}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.name}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Email <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                      value={editedData.email}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.email}
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Mobile <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Mobile"
                      className="form-control no-arrows"
                      name="mobile"
                      value={editedData.mobile}
                      onChange={handleEditChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {editValidationErrors.mobile}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Select Status <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      value={editedData.status}
                      name="status"
                      className="mb-3"
                      aria-label="Small select example"
                    >
                      <option value="">Select Status</option>
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="INACTIVE">INACTIVE</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                isDisable={editButtonDisable}
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle
              id="VerticallyCenteredExample"
              className="fs-6 fw-semibold"
            >
              DELETE OUTLET USER
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteOutletUser}>
            <CModalBody>
              Are you sure you want to delete this outlet user ?
            </CModalBody>
            <CModalFooter>
              <CButton
                style={{ borderRadius: "18px" }}
                className="btn btn-warning"
                onClick={() => setOpenDeleteModal(false)}
              >
                CLOSE
              </CButton>

              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openViewModal && (
        <CModal
          size="s"
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader style={{ backgroundColor: "#fff", color: "black" }}>
            <CModalTitle
              id="VerticallyCenteredExample"
              className="fs-5 fw-semibold"
              style={{ marginLeft: "" }}
            >
              VIEW OUTLET USER DETAILS
            </CModalTitle>
          </CModalHeader>
          <CModalBody style={{ backgroundColor: "#f8f9fa" }}>
            <div className="">
              <TableWithTitle
                title="OUTLET USER DETAILS"
                data={[
                  { label: "ID Number", value: outletUserDetail?.idNumber },
                  { label: "Name", value: outletUserDetail?.name },
                  { label: "Email", value: outletUserDetail?.email },
                  { label: "Role", value: outletUserDetail?.role },
                  { label: "Mobile", value: outletUserDetail?.mobile },
                  { label: "Status", value: outletUserDetail?.status },
                ]}
              />
              <TableWithTitle
                title="OUTLET DETAILS"
                data={[
                  { label: "Name", value: outletUserDetail?.outlet?.name },
                  {
                    label: "City, State",
                    value: `${outletUserDetail?.outlet?.city}, ${outletUserDetail?.outlet?.state}`,
                  },
                ]}
              />
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              style={{ borderRadius: "18px" }}
              className="btn btn-warning"
              onClick={() => setOpenViewModal(false)}
            >
              CLOSE
            </CButton>
          </CModalFooter>
        </CModal>
      )}

      {addNewOpenModal ? (
        <CModal
          alignment="center"
          visible={addNewOpenModal}
          onClose={() => setAddNewOpenModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>ADD OUTLET USER</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Outlet <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="outlet"
                      className="mb-3"
                      aria-label="Small select example"
                    >
                      <option>Select Outlet</option>
                      {outlets.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {validationErrors.outlet}
                    </div>
                  </div>
                </CCol>

                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Role <span className="text-danger">*</span>
                    </CFormLabel>

                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="role"
                      className="mb-3"
                      aria-label="Small select example"
                    >
                      <option>Select Role</option>
                      <option value="MANAGER">MANAGER</option>
                      <option value="CASHIER">CASHIER</option>
                      <option value="CAFE-MANAGER">CAFE-MANAGER</option>
                      
                    </CFormSelect>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {validationErrors.role}
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      label=""
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {validationErrors.name}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Email <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                      value={inputData.email}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {validationErrors.email}
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Mobile <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={1}
                      minLength={10}
                      type="number"
                      placeholder="Mobile"
                      className="form-control no-arrows"
                      name="mobile"
                      value={inputData.mobile}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {validationErrors.mobile}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Password <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="password"
                      placeholder="Password"
                      className="form-control"
                      name="password"
                      value={inputData.password}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {validationErrors.password}
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                isDisable={disableButton}
                title="ADD OUTLET USER"
                isLoading={isLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </CContainer>
  );
};

export default OutletUsers;
