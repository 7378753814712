import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormCheck, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow } from '@coreui/react';
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { PrimaryButton } from '../../components';
import { format } from 'date-fns';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        borderRadius: '6px',
        minWidth: "200px",
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
        "&:hover": {
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#6c757d"
    }),
};

const CreateCoupon = () => {
    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [couponData, setCouponData] = useState({
        isBogoCoupon: false,
        isMrpDiscount: false,
        isHidden: "",
        title: "",
        couponCode: "",
        sizeUpgradeAddon: "",
        description: "",
        discountPercent: "",
        discountAmount: "",
        maxDiscountAmount: "",
        minOrderAmount: "",
        discountPrice: "",
        validityStartDate: "",
        validityEndDate: "",
        usageType: "",
        discountPrice: "",
        applicableQuantityType: "",
        dayOfWeek: "",
        startTime: "",
        endTime: "",
        termsAndConditions: [],
        isComboCategoryCoupon: false,
        maxAddonsAllowed: null
    });
    const [sizeCouponProduct, setSizeCouponProduct] = useState({
        applicableProducts: []
    })
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [categoriesData, setCategoriesData] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [levels, setLevels] = useState([]);
    const [addBtnLoading, setAddBtnLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedIncludedOptions, setSelectedIncludedOptions] = useState([]);
    const [selectedCouponOption, setSelectedCouponOption] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedDependentProducts, setSelectedDependentProducts] = useState([]);
    const [selectedDependentCategories, setSelectedDependentCategories] = useState([]);
    const [selectedExcludedProducts, setSelectedExcludedProducts] = useState([]);
    const [selectedLevelOptions, setSelectedLevelOptions] = useState([]);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [currentTerm, setCurrentTerm] = useState('');
    const [addonList, setAddonList] = useState([]);
    const [addonTitleId, setAddonTitleId] = useState([]);
    const [addonValueList, setAddonValueList] = useState([]);
    const [selectedAddonValues, setSelectedAddonValues] = useState([]);
    const [productAddonsList, setProductAddonsList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [exProductList, setExProductList] = useState([]);
    const [primaryComboCategories, setPrimaryComboCategories] = useState([])
    const [secondaryComboCategories, setSecondaryComboCategories] = useState([]);
    const [comboCategoryType, setComboCategoryType] = useState(null); // State to handle checkbox selection

    const handleComboCategoryChange = (type) => {
        setComboCategoryType((prevType) => (prevType === type ? null : type)); // Toggle between selected and unselected
    };

    // const outletData = outlets.map((outlet) => ({
    //     value: outlet._id,
    //     label: `${outlet.name} (${outlet.city})`,
    // }));

    // Outlet Data Mapping
    // Outlet Data Mapping
    const outletData = outlets
        .filter(
            (outlet) =>
                !selectedOptions.some((selected) => selected.value === outlet._id) && // Exclude selected Excluded outlets
                !selectedIncludedOptions.some((selected) => selected.value === outlet._id) // Exclude selected Included outlets
        )
        .map((outlet) => ({
            value: outlet._id,
            label: `${outlet.name} (${outlet.city})`,
        }));

    const productsInfo = productsList.map((product) => ({
        value: product?._id,
        label: `${product?.name}`
    }))

    const exProductsInfo = exProductList.map((pro) => ({
        value: pro?._id,
        label: `${pro?.name}`
    }))

    const addonsInfo = productAddonsList?.map((add) => ({
        value: add?._id,
        label: <span>
            {add?.addonTitle} <span className=''>{" -> "}</span> <span style={{ color: "grey" }}> {add?.product?.name}</span>
        </span>
    }))

    // const addonsValueInfo = addonValueList
    // Map the originalAddonValues
    const addonsValueInfo = addonValueList?.flatMap((addon) =>
        addon?.originalAddonValues?.map((original) => ({
            adTitleId: addon._id,
            value: original?._id, // Assuming originalAddonValues have an _id property
            label: (
                <span>
                    {original?.value || "No Name"}{" "}
                    <span className="">{" -> "}</span> <span style={{ color: "grey" }}> {addon?.addonTitle}</span> <span className="">{" >> "}</span> <span style={{ color: "grey" }}> {addon?.product?.name}</span>
                </span>
            ),
        }))
    );

    const levelsData = levels.map((level) => ({
        value: level._id,
        label: `${level.sequenceNo}. ${level.title}`
    }))

    const categoriesList = categoriesData.map((category) => ({
        value: category._id,
        label: `${category.name}`
    }));

    // Toggles the selected checkbox button, deselecting it if clicked again.
    const handleCheckboxChange = (id) => {
        setSelectedCheckbox((prev) => (prev === id ? null : id));
        setCouponData((prevData) => ({
            ...prevData,
            isBogoCoupon: id === 'isBogoCoupon',
            isComboCategoryCoupon: id === 'isComboCoupon' ? prevData.isComboCategoryCoupon : false,
        }));
    };

    // Updates coupon data state with values from input fields.
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedValue = name === "couponCode" ? value.toUpperCase() : value;

        setSizeCouponProduct((prev) => ({
            ...prev,
            [name]: name === "applicableProducts" ? [value] : value
        }));

        if (name === "applicableProducts") {
            setSelectedProduct(value);
        }

        setCouponData((prev) => ({ ...prev, [name]: updatedValue }));
    }

    // Sets the start date for the coupon, or logs an error if date is invalid.
    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    // Sets the end date for the coupon, or logs an error if date is invalid.
    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const addTerm = () => {
        if (!currentTerm.trim()) return;

        setCouponData((prevData) => ({
            ...prevData,
            termsAndConditions: [...(prevData?.termsAndConditions || []), currentTerm],
        }));

        setCurrentTerm(''); // Clear input after adding
    };

    const removeTerm = (index) => {
        setCouponData((prevData) => ({
            ...prevData,
            termsAndConditions: (prevData?.termsAndConditions || []).filter(
                (_, i) => i !== index
            ),
        }));
    };


    //Sets Excluded Outlets
    const handleSelectExOutletChange = (out) => {
        setSelectedOptions(out);
    }

    //Sets Included Outlets
    const handleSelectIncOutletChange = (out) => {
        setSelectedIncludedOptions(out);
    }

    // Sets Primary Combo Categories
    const handleSelectPrimaryComboCategories = (cat) => {
        setPrimaryComboCategories(cat)
    }

    // Sets Secondary Combo Categories
    const handleSelectSecondaryComboCategories = (cat) => {
        setSecondaryComboCategories(cat)
    }

    //Sets Applicable Categories
    const handleSelectCategoryChange = (cats) => {
        setSelectedCategories(cats);
        getCategoryProductAddons(cats, selectedProducts);
        getProductsList(cats);
    }

    //Sets Applicable Products
    const handleSelectProductChange = (prods) => {
        setSelectedProducts(prods);
        getCategoryProductAddons(selectedCategories, prods);
    }

    //Sets Dependent Products
    const handleSelectDeProductsChange = (depro) => {
        setSelectedDependentProducts(depro);
    }

    //Sets Dependent Products
    const handleSelectDeCategoriesChange = (decat) => {
        setSelectedDependentCategories(decat);
    }

    //Sets Excluded Products
    const handleSelectExProductsChange = (expro) => {
        setSelectedExcludedProducts(expro);
    }

    //Sets Addons Title
    const handleSelectAddonsChange = (add) => {
        console.log("Addons of Handle CHange :", add);
        // setSelectedAddons(add);

        // Extract all addon values into the `addonTitleId` array
        const selectedIds = add.map((item) => item.value);
        getCategoryProductAddons(selectedCategories, selectedProducts, selectedIds);
        // Update the state with the selected IDs and addons
        setAddonTitleId(selectedIds);

        // Map through the array of selected addon values
        let combinedAddonTitleData = add.map((addon) => ({
            name: addon?.label?.props?.children[0], // Extract the label
            id: addon?.value                       // Extract the value
        }));
        setSelectedAddons(combinedAddonTitleData)

        console.log("Combined Addon Title Data:", combinedAddonTitleData);


    }

    //Sets Addon Values
    const handleSelectAddonValueChange = (val) => {
        // Map through the array of selected addon values
        let combinedData = val.map((addon) => ({
            name: addon?.label?.props?.children[0], // Extract the label
            adValueId: addon?.value,                       // Extract the value
            adTitleId: addon?.adTitleId
        }));
        setSelectedAddonValues(combinedData)
    }

    //Sets Levels
    const handleSelectLevelChange = (selected) => {
        setSelectedLevelOptions(selected);
    }

    // Fetches the category list from the server and updates state. 
    const getCategoryList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });

            if (response?.data?.status === true) {
                setCategoriesData(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Fetches the products list from the server and updates state.
    const getProductsList = async (catId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                setProductsList(response?.data?.data?.products);

                let excludedProducts = response?.data?.data?.products?.filter((fi) =>
                    catId.some((category) => category.value === fi?.category?._id)
                );

                setExProductList(excludedProducts);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Fetches the outlet list from the server and updates state.
    const getOutlets = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("outlet", { headers });

            if (response?.data?.status === true) {
                setOutlets(response?.data?.data?.outlets);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getLevels = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                setLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // const getBestCoupons = async () => {
    //     try {
    //         const headers = {
    //             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lIjoiRnJpIE5vdiAyMiAyMDI0IDA1OjU1OjIwIEdNVCswMDAwIChDb29yZGluYXRlZCBVbml2ZXJzYWwgVGltZSkiLCJfaWQiOiI2NzNkNzk1NGZmYTFmY2FlOTkyODZiYzIiLCJyb2xlIjoiVSIsImlhdCI6MTczMjI1NDkyMH0.HIhcOtgEfkXmwN15hLbiIeGvtOT_ba3TiiaEUEQg4EA` // Assuming 'Bearer' token format
    //         };

    //         // const url = `${API.BASE_URL}masters/category/getValidCouponsForAnUser`; // Use correct template string syntax

    //         const response = await axios.get("masters/category/getValidCouponsForAnUser", { headers }); // Ensure the correct spelling of 'axios'
    //         console.log("Response of best coupons:", response); // Fix the typo in the log
    //     } catch (error) {
    //         console.error("Error in best coupons:", error); // Fix the variable name and use 'error' to log actual error details
    //     }
    // };

    // useEffect(() => {
    //     getBestCoupons();
    // }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAddBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }

            let selectedApplicableCategory = selectedCategories?.map((cat) => cat?.value);
            let selectedApplicableProduct = selectedProducts?.map((prod) => prod?.value);
            let selectedExcludedOutlets = selectedOptions?.map((options) => options?.value);
            let selectedIncludedOutlets = selectedIncludedOptions?.map((options) => options?.value);
            let selectedDepProducts = selectedDependentProducts?.map((dep) => dep?.value);
            let selectedDepCategories = selectedDependentCategories?.map((dec) => dec?.value);
            let selectedExcProducts = selectedExcludedProducts?.map((prods) => prods?.value);
            let selectedAddonTitle = selectedAddons?.map((add) => add?.value);
            let selectedLevels = selectedLevelOptions?.map((lev) => lev?.value);
            let selectedPrimaryComboCats = primaryComboCategories?.map((cat) => cat.value)
            let selectedSecondaryComboCats = secondaryComboCategories?.map((cat) => cat.value)
            const selectedProductsData = sizeCouponProduct?.applicableProducts.map((c) => c);

            // Construct dependantAddonInfo
            let dependantAddonInfo = selectedAddonValues.map((addonValue) => {
                const matchedAddonTitle = selectedAddons.find(
                    (titleId) => titleId.id === addonValue.adTitleId // Match the addonValue with its title ID
                );
                return {
                    _id: matchedAddonTitle?.id, // Addon Title ID
                    addonValue: addonValue?.name, // Addon Value Name
                    addonValueId: addonValue?.adValueId  // Addon Value ID
                };
            });

            let updatedCouponData = {
                ...couponData,
                applicableCategories: selectedApplicableCategory,
                applicableProducts: selectedApplicableProduct,
                excludedOutlets: selectedExcludedOutlets,
                includedOutlets: selectedIncludedOutlets,
                dependantProducts: selectedDepProducts,
                dependantCategories: selectedDepCategories,
                excludedProductList: selectedExcProducts,
                dependantAddonInfo: dependantAddonInfo,
                applicableLevels: selectedLevels,
                primaryComboCategories: selectedPrimaryComboCats,
                secondaryComboCategories: selectedSecondaryComboCats
            }

            if (selectedProductsData?.length > 0) {
                updatedCouponData = {
                    ...updatedCouponData,
                    applicableProducts: selectedProductsData
                }
            }

            if (updatedCouponData?.primaryComboCategories?.length === 0) {
                delete updatedCouponData?.primaryComboCategories
            }

            if (updatedCouponData?.secondaryComboCategories?.length === 0) {
                delete updatedCouponData?.secondaryComboCategories
            }

            if (updatedCouponData?.applicableCategories?.length === 0) {
                delete updatedCouponData?.applicableCategories
            }

            // if (updatedCouponData?.maxAddonsAllowed === null) {
            //     updatedCouponData?.maxAddonsAllowed
            // }

            if (updatedCouponData?.applicableProducts?.length === 0) {
                delete updatedCouponData?.applicableProducts
            }

            if (updatedCouponData?.excludedOutlets?.length === 0) {
                delete updatedCouponData?.excludedOutlets
            }

            if (updatedCouponData?.includedOutlets?.length === 0) {
                delete updatedCouponData?.includedOutlets
            }

            if (updatedCouponData?.dependantAddonInfo?.length === 0) {
                delete updatedCouponData?.dependantAddonInfo
            }

            if (updatedCouponData?.dependantProducts?.length === 0) {
                delete updatedCouponData?.dependantProducts
            }

            if (updatedCouponData?.dependantCategories?.length === 0) {
                delete updatedCouponData?.dependantCategories
            }

            if (updatedCouponData?.excludedProductList?.length === 0) {
                delete updatedCouponData?.excludedProductList
            }

            if (updatedCouponData?.applicableLevels?.length === 0) {
                delete updatedCouponData?.applicableLevels
            }

            if (updatedCouponData?.startTime === "") {
                delete updatedCouponData.startTime
            }

            if (updatedCouponData?.endTime === "") {
                delete updatedCouponData.endTime
            }

            if (updatedCouponData?.endTime === "") {
                delete updatedCouponData.endTime
            }

            if (updatedCouponData?.usageType === "") {
                delete updatedCouponData.usageType
            }

            if (updatedCouponData?.dayOfWeek === "" || updatedCouponData?.dayOfWeek === "all") {
                delete updatedCouponData.dayOfWeek
            }

            if (updatedCouponData?.applicableCategories?.length === 0) {
                delete updatedCouponData?.applicableCategories
            }

            if (updatedCouponData?.isHidden === "") {
                delete updatedCouponData.isHidden
            }

            if (updatedCouponData?.discountAmount === "") {
                delete updatedCouponData?.discountAmount
            }

            if (updatedCouponData?.discountPercent === "") {
                delete updatedCouponData?.discountPercent
            }

            if (updatedCouponData?.discountPrice === "") {
                delete updatedCouponData?.discountPrice
            }

            if (updatedCouponData?.isMrpDiscount === "") {
                delete updatedCouponData?.isMrpDiscount
            }

            if (updatedCouponData?.maxDiscountAmount === "") {
                delete updatedCouponData?.maxDiscountAmount
            }

            if (updatedCouponData?.minOrderAmount === "") {
                delete updatedCouponData?.minOrderAmount
            }

            if (updatedCouponData?.applicableQuantityType === "") {
                delete updatedCouponData?.applicableQuantityType
            }

            if (updatedCouponData?.termsAndConditions?.length === 0) {
                delete updatedCouponData?.termsAndConditions
            }

            if (updatedCouponData?.sizeUpgradeAddon === "") {
                delete updatedCouponData.sizeUpgradeAddon
            }

            if (updatedCouponData?.validityStartDate === "" || updatedCouponData?.validityStartDate === null) {
                delete updatedCouponData.validityStartDate
            }

            if (updatedCouponData?.validityEndDate === "" || updatedCouponData?.validityEndDate === null) {
                delete updatedCouponData.validityEndDate
            }

            const response = await axios.post("coupons", updatedCouponData, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset();
                setSelectedCheckbox(null);
                setSelectedOptions([]);
                setSelectedIncludedOptions([]);
                setSelectedDependentCategories([]);
                setSelectedDependentProducts([]);
                setSelectedCategories([]);
                setSelectedProducts([]);
                setSelectedLevelOptions([]);
                setSelectedExcludedProducts([]);
                setSelectedAddons([]);
                setSelectedCouponOption("");
                setPrimaryComboCategories([]);
                setSecondaryComboCategories([])
                setCouponData({
                    title: "",
                    isHidden: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                    discountPercent: "",
                    discountAmount: "",
                    maxDiscountAmount: "",
                    minOrderAmount: "",
                    validityStartDate: "",
                    validityEndDate: "",
                    applicableLevels: "",
                    dayOfWeek: "",
                    endTime: "",
                    startTime: "",
                    maxAddonsAllowed:null,
                    isMrpDiscount: false
                });
                setFromDate(null);
                setToDate(null);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage =
                        error?.response?.data?.data?.message ||
                        error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setAddBtnLoading(false);
        }
    }

    const getCategoryProductAddons = async (categ, prod, addIds) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }

            let categories = categ?.map((opt) => opt?.value);
            let products = prod?.map((opt) => opt?.value);
            // Construct the payload with arrays
            const payload = {
                applicableCategories: categories,
                applicableProducts: products,
            };

            if (payload?.applicableCategories?.length === 0) {
                delete payload?.applicableCategories
            }

            if (payload?.applicableProducts?.length === 0) {
                delete payload?.applicableProducts
            }

            console.log("Payload to send:", payload);

            const response = await axios.post("/coupons/get-dependant-product-category-addons", payload, { headers });
            setProductAddonsList(response?.data?.data?.addons);
            let filteredAddonValues = response?.data?.data?.addons?.filter((val) =>
                addIds?.includes(val?._id) // Check if val._id exists in addIds
            );
            setAddonValueList(filteredAddonValues);
        } catch (error) {
            console.log("Error :", error);
        }
    }

    const getAddonProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product-addon?limit=1000&page=1", { headers });
            if (response?.data?.status === true) {
                const addOns = response?.data?.data?.productAddons
                // const sizeAddons = addOns.filter((item) => item?.addonTitle === "Size");
                const productsWithSizeAddon = addOns.filter((item) =>
                    item.addons.some((addon) => addon.addonTitle.toLowerCase() === 'size')
                );
                setAddonList(productsWithSizeAddon)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const filteredAddons = selectedProduct
        ? addonList.filter(rm => rm.product._id === selectedProduct)
        : [];
    const updatedFilteredAddons = filteredAddons?.map((item) => item.addons?.map((mm) => mm));


    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setCouponData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    useEffect(() => {
        getCategoryList();
        getProductsList();
        getOutlets();
        getLevels();
        getAddonProductsList();
    }, []);

    // Clear selected values when the field is disabled For Applicable Categories
    useEffect(() => {
        if (
            selectedCheckbox === "isBogoCoupon" ||
            selectedCheckbox === "isSizeUpgradeCoupon" ||
            selectedCheckbox === "isSpecialProductCoupon" ||
            selectedCheckbox === "isUniversalCoupon" ||
            selectedCheckbox === "isCartCoupon"
        ) {
            setSelectedCategories([]); // Clear categories when disabled 
        }
    }, [selectedCheckbox]);

    // <--------------- Applicable Products ------------->
    useEffect(() => {
        if (
            selectedCheckbox === "isBogoCoupon" ||
            selectedCheckbox === "isSpecialCategoryCoupon" ||
            selectedCheckbox === "isCategoryCoupon" ||
            selectedCheckbox === "isUniversalCoupon" ||
            selectedCheckbox === "isCartCoupon"
        ) {
            setSelectedProducts([]); // Clear products when disabled
        }
    }, [selectedCheckbox]);

    // Effects to reset fields when disabled
    useEffect(() => {
        if (
            selectedCheckbox === "isBogoCoupon" ||
            selectedCheckbox === "isSizeUpgradeCoupon" ||
            selectedCheckbox === "isCartCoupon" ||
            selectedCheckbox === "isUniversalCoupon"
        ) {
            setSelectedAddons([]); // Clear addons
            setSelectedAddonValues([]); // Clear addon values
            setSelectedExcludedProducts([]); // Clear excluded products
        }
    }, [selectedCheckbox]);

    useEffect(() => {
        if (
            selectedCheckbox === "isBogoCoupon" ||
            selectedCheckbox === "isSizeUpgradeCoupon" ||
            selectedCheckbox === "isCartCoupon" ||
            selectedCheckbox === "isUniversalCoupon"
        ) {
            setSelectedDependentCategories([]); // Clear dependent categories
            setSelectedDependentProducts([]); // Clear dependent products
        }
    }, [selectedCheckbox]);

    // Reset specific fields when they are disabled based on selectedCheckbox
    useEffect(() => {
        if (selectedCheckbox === "isUniversalCoupon" ||
            selectedCheckbox === "isBogoCoupon" ||
            selectedCheckbox === "isCartCoupon" ||
            selectedCheckbox === "isProductCoupon" ||
            selectedCheckbox === "isCategoryCoupon" ||
            selectedCheckbox === "isSpecialCategoryCoupon" ||
            selectedCheckbox === "isSpecialProductCoupon") {
            setCouponData((prev) => ({
                ...prev,
                sizeUpgradeAddon: "",
                discountPercent: "",
                discountAmount: "",
                maxDiscountAmount: "",
                discountPrice: "",
                applicableQuantityType: "",
            }));
        }
    }, [selectedCheckbox]);

    return (
        <CContainer>
            <CCard className='mb-2'>
                <CCardHeader>
                    <CCardTitle>ADD COUPON</CCardTitle>
                </CCardHeader>
                <CForm onSubmit={handleSubmit}>
                    <CCardBody>
                        <CRow className="mb-3">
                            <CCol xs={12} md={12} lg={12} className='d-flex justify-content-center mb-3'>
                                {selectedCheckbox ? (
                                    <span className='fw-semibold text-white' style={{ fontSize: '15px', backgroundColor: '#3AB857', padding: '5px 15px', borderRadius: '18px' }}>
                                        {
                                            [
                                                { id: 'isBogoCoupon', description: 'Description - BOGO coupon allows you to buy one and get another one free.' },
                                                { id: 'isUniversalCoupon', description: 'Description - Universal coupon can be applied to any item in the store.' },
                                                { id: 'isCartCoupon', description: 'Description - Cart coupon gives a discount on the entire cart.' },
                                                { id: 'isSizeUpgradeCoupon', description: 'Description - Size upgrade coupon lets you upgrade the product size for free or a discount.' },
                                                { id: 'isProductCoupon', description: 'Description - Product coupon provides a discount on a specific product.' },
                                                { id: 'isCategoryCoupon', description: 'Description - Category coupon gives a discount on all items in a particular category.' },
                                                { id: 'isSpecialCategoryCoupon', description: 'Description - Special category coupon applies to selected special categories only.' },
                                                { id: 'isSpecialProductCoupon', description: 'Description - Special product coupon offers discounts on special, featured products.' },
                                                { id: 'isComboCoupon', description: 'Description - Combo Coupon type allow you to create a coupon in which two products from two category group can be sold at specific price or specific discount can be applied.' },
                                            ].find((item) => item.id === selectedCheckbox)?.description
                                        }
                                    </span>
                                ) : <span className='fw-semibold text-muted' style={{ fontSize: '15px', backgroundColor: '#E0FFD9', padding: '5px 15px', borderRadius: '18px' }}>Please Select a Coupon Type From Above</span>}
                            </CCol>
                            {[
                                { id: 'isBogoCoupon', label: 'BOGO COUPON', description: 'BOGO coupon allows you to buy one and get another one free.' },
                                { id: 'isUniversalCoupon', label: 'UNIVERSAL COUPON', description: 'Universal coupon can be applied to any item in the store.' },
                                { id: 'isCartCoupon', label: 'CART COUPON', description: 'Cart coupon gives a discount on the entire cart.' },
                                { id: 'isSizeUpgradeCoupon', label: 'SIZE UPGRADE COUPON', description: 'Size upgrade coupon lets you upgrade the product size for free or a discount.' },
                                { id: 'isProductCoupon', label: 'PRODUCT COUPON', description: 'Product coupon provides a discount on a specific product.' },
                                { id: 'isCategoryCoupon', label: 'CATEGORY COUPON', description: 'Category coupon gives a discount on all items in a particular category.' },
                                { id: 'isSpecialCategoryCoupon', label: 'SPECIAL CATEGORY COUPON', description: 'Special category coupon applies to selected special categories only.' },
                                { id: 'isSpecialProductCoupon', label: 'SPECIAL PRODUCT COUPON', description: 'Special product coupon offers discounts on special, featured products.' },
                                { id: 'isComboCoupon', label: 'COMBO COUPON', description: 'Special product coupon offers discounts on special, featured products.' },
                            ].map((button) => (
                                <CCol md={3} className="mb-3" key={button.id}>
                                    <CButton
                                        color={selectedCheckbox === button.id ? 'primary' : 'secondary'}
                                        variant={selectedCheckbox === button.id ? 'solid' : 'outline'}
                                        onClick={() => handleCheckboxChange(button.id)}
                                        className={`toggle-button ${selectedCheckbox === button.id ? 'selected' : ''}`}
                                    >
                                        {button.label}
                                    </CButton>
                                </CCol>
                            ))}
                        </CRow>

                        <CRow>
                            {selectedCheckbox === 'isComboCoupon' && (
                                <CCol xs={4} md={4} lg={4} className="d-flex mb-3">
                                    <CFormCheck
                                        label="Is Combo Category Coupon ?"
                                        id="comboCategoryCheckbox"
                                        checked={couponData.isComboCategoryCoupon}
                                        onChange={(e) => setCouponData((prev) => ({ ...prev, isComboCategoryCoupon: e.target.checked }))}
                                    />
                                </CCol>
                            )}
                        </CRow>

                        <CRow>
                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Title <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput placeholder="Title" name="title"
                                        value={couponData.title || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Coupon Code <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        placeholder="Coupon Code"
                                        name="couponCode"
                                        value={couponData.couponCode || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </CCol>

                            <CCol className='mb-3' md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Validity Start Date
                                    </CFormLabel>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={handleFromDateChange}
                                        placeholderText="Start Date"
                                        className="form-control"
                                    />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Validity End Date
                                    </CFormLabel>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={handleToDateChange}
                                        placeholderText="End Date"
                                        className="form-control"
                                    />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Usage Type <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormSelect
                                        name="usageType"
                                        value={couponData.usageType}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Usage</option>
                                        <option value="ONE-TIME">ONE-TIME</option>
                                        <option value="MULTIPLE">MULTIPLE</option>
                                    </CFormSelect>
                                </div>
                            </CCol>

                            <CCol>
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        Select Day of a week
                                    </CFormLabel>
                                    <CFormSelect
                                        size="sm"
                                        onChange={handleInputChange}
                                        name="dayOfWeek"
                                        className="mb-3"
                                        aria-label="Small select example"
                                        value={couponData?.dayOfWeek}
                                    >
                                        <option value="">Select Day</option>
                                        <option value="0">SUNDAY</option>
                                        <option value="1">MONDAY</option>
                                        <option value="2">TUESDAY</option>
                                        <option value="3">WEDNESDAY</option>
                                        <option value="4">THURSDAY</option>
                                        <option value="5">FRIDAY</option>
                                        <option value="6">SATURDAY</option>
                                        <option value="all">ANY DAY OF THE WEEK</option>
                                    </CFormSelect>
                                </div>
                            </CCol>

                            <CCol md="3" lg="3">
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        Start Time
                                    </CFormLabel>
                                    <CFormInput
                                        type="time"
                                        placeholder="Opening Time"
                                        className="form-control"
                                        name="startTime"
                                        value={couponData.startTime}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </CCol>

                            <CCol md="3" lg="3">
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        End Time
                                    </CFormLabel>
                                    <CFormInput
                                        type="time"
                                        placeholder="Closing Time"
                                        className="form-control"
                                        name="endTime"
                                        value={couponData.endTime}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </CCol>

                            <CCol md="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Included Outlets
                                </CFormLabel>
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    value={selectedIncludedOptions}
                                    onChange={handleSelectIncOutletChange}
                                    options={outletData}
                                    styles={customStyles}
                                    placeholder="Select Included Outlets"
                                    isDisabled={!!selectedOptions?.length}
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol>

                            <CCol md="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Excluded Outlets
                                </CFormLabel>
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    value={selectedOptions}
                                    onChange={handleSelectExOutletChange}
                                    options={outletData}
                                    styles={customStyles}
                                    placeholder="Select Excluded Outlets"
                                    isDisabled={!!selectedIncludedOptions?.length}
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol>


                            {/* Select Applicable Categories Input field */}
                            {
                                (selectedCheckbox === null || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isCategoryCoupon") &&
                                <CCol md={6} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Applicable Categories
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        // isDisabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialProductCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isComboCoupon"}
                                        components={animatedComponents}
                                        options={categoriesList}
                                        value={selectedCategories || []}
                                        onChange={handleSelectCategoryChange}
                                        placeholder="Select Applicable Categories"
                                        closeMenuOnSelect={false}
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>
                            }

                            {/* Select Applicable Product only comes for sizeupgrade coupon */}

                            {
                                selectedCheckbox === 'isSizeUpgradeCoupon' && <CCol md="6" className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Applicable Product <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        options={addonList.map((rm) => ({
                                            value: rm?.product?._id,
                                            label: rm?.product?.name,
                                        }))}
                                        value={
                                            sizeCouponProduct.applicableProducts.length > 0
                                                ? addonList
                                                    .map((rm) => ({ value: rm?.product?._id, label: rm?.product?.name }))
                                                    .find((option) => option.value === sizeCouponProduct.applicableProducts[0])
                                                : null
                                        }
                                        onChange={(selectedOption) => {
                                            if (typeof selectedOption === 'object' && selectedOption !== null) {
                                                handleInputChange({ target: { name: 'applicableProducts', value: selectedOption.value } });
                                            } else {
                                                handleInputChange({ target: { name: 'applicableProducts', value: '' } });
                                            }
                                        }}
                                        classNamePrefix="react-select"
                                        isClearable
                                        placeholder="Select Product"
                                    />
                                </CCol>
                            }

                            {/* Select Applicable Products input field */}
                            {
                                (selectedCheckbox === null || selectedCheckbox === 'isSpecialProductCoupon' || selectedCheckbox === "isProductCoupon") &&
                                (<CCol md={6}>
                                    <CFormLabel className="fw-semibold">
                                        Select Applicable Products {selectedCheckbox === "isSizeUpgradeCoupon" ? (<span className='fw-semibold' style={{ fontSize: '12px', color: 'blueviolet' }}>(Select Only One Product For Size Upgrade Coupon)</span>) : null}
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        isDisabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isComboCoupon"}
                                        components={animatedComponents}
                                        options={productsInfo}
                                        value={selectedProducts || []}
                                        onChange={handleSelectProductChange}
                                        placeholder="Select Applicable Products"
                                        styles={customStyles}
                                        closeMenuOnSelect={false}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>)
                            }

                            {/* <CCol md="6" lg="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Addons
                                </CFormLabel>
                                <Select
                                    isMulti
                                    isDisabled={selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isSizeUpgradeCoupon"}
                                    components={animatedComponents}
                                    value={selectedAddons?.value}
                                    onChange={handleSelectAddonsChange}
                                    options={addonsInfo}
                                    styles={customStyles}
                                    placeholder="Select Addons"
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol> */}

                            {/* <CCol md="6" lg="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Addon Values
                                </CFormLabel>
                                <Select
                                    isMulti
                                    isDisabled={selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isSizeUpgradeCoupon"}
                                    components={animatedComponents}
                                    value={selectedAddonValues?.id}
                                    onChange={handleSelectAddonValueChange}
                                    options={addonsValueInfo}
                                    styles={customStyles}
                                    placeholder="Select Addons"
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol> */}

                            {/* Select Dependent Categories input field */}
                            {
                                (selectedCheckbox === null || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === 'isSpecialProductCoupon' || selectedCheckbox === "isProductCoupon") && <CCol md="6" className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Dependent Categories
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        components={animatedComponents}
                                        value={selectedDependentCategories}
                                        onChange={handleSelectDeCategoriesChange}
                                        isDisabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox == "isCartCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isComboCoupon"}
                                        options={categoriesList}
                                        styles={customStyles}
                                        placeholder="Select Dependent Categories"
                                        closeMenuOnSelect={false}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>
                            }
                            {/* Select Dependent Products input field */}
                            {
                                (selectedCheckbox === null || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === 'isSpecialProductCoupon' || selectedCheckbox === "isProductCoupon") && (
                                    <CCol md="6" className='mb-3'>
                                        <CFormLabel className="fw-semibold">
                                            Select Dependent Products
                                        </CFormLabel>
                                        <Select
                                            isMulti
                                            components={animatedComponents}
                                            value={selectedDependentProducts}
                                            onChange={handleSelectDeProductsChange}
                                            isDisabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isUniversalCoupon"}
                                            options={productsInfo}
                                            styles={customStyles}
                                            placeholder="Select Dependent Products"
                                            closeMenuOnSelect={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#e9ecef",
                                                    primary: "#007bff",
                                                },
                                            })}
                                        />
                                    </CCol>
                                )
                            }

                            {/* Select Excluded Products input field */}
                            {
                                (selectedCheckbox === null || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === 'isSpecialProductCoupon' || selectedCheckbox === "isProductCoupon") && (
                                    <CCol md="6" lg="6" className='mb-3'>
                                        <CFormLabel className="fw-semibold">
                                            Select Excluded Products
                                        </CFormLabel>
                                        <Select
                                            isMulti
                                            isDisabled={selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isSpecialProductCoupon"}
                                            components={animatedComponents}
                                            value={selectedExcludedProducts}
                                            onChange={handleSelectExProductsChange}
                                            options={exProductsInfo}
                                            styles={customStyles}
                                            placeholder="Select Excluded Products"
                                            closeMenuOnSelect={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#e9ecef",
                                                    primary: "#007bff",
                                                },
                                            })}
                                        />
                                    </CCol>
                                )
                            }

                            {/* Select Applicable Levels -> common field for all coupons type  */}
                            <CCol md="6" lg="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Applicable Levels
                                </CFormLabel>
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    value={selectedLevelOptions}
                                    onChange={handleSelectLevelChange}
                                    options={levelsData}
                                    styles={customStyles}
                                    placeholder="Select Applicable Levels"
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol>

                            {/* Primary Combo Categories and Secondary Combo Categories */}

                            {
                                selectedCheckbox === null || selectedCheckbox === 'isComboCoupon' && <>
                                    <CCol md="6" className='mb-3'>
                                        <CFormLabel className="fw-semibold">
                                            Primary Combo Categories
                                        </CFormLabel>
                                        <Select
                                            isMulti
                                            components={animatedComponents}
                                            value={primaryComboCategories}
                                            onChange={handleSelectPrimaryComboCategories}
                                            options={categoriesList.filter(
                                                (category) =>
                                                    !secondaryComboCategories?.some(
                                                        (cat) => cat.value === category.value
                                                    )
                                            )}
                                            styles={customStyles}
                                            placeholder="Primary Combo Categories"
                                            // isDisabled={!!selectedOptions?.length}
                                            closeMenuOnSelect={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#e9ecef",
                                                    primary: "#007bff",
                                                },
                                            })}
                                        />
                                    </CCol>

                                    <CCol md="6" className='mb-3'>
                                        <CFormLabel className="fw-semibold">
                                            Secondary Combo Categories
                                        </CFormLabel>
                                        <Select
                                            isMulti
                                            components={animatedComponents}
                                            value={secondaryComboCategories}
                                            onChange={handleSelectSecondaryComboCategories}
                                            options={categoriesList.filter(
                                                (category) =>
                                                    !primaryComboCategories?.some(
                                                        (cat) => cat.value === category.value
                                                    )
                                            )}
                                            styles={customStyles}
                                            placeholder="Secondary Combo Categories"
                                            // isDisabled={!!selectedOptions?.length}
                                            closeMenuOnSelect={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#e9ecef",
                                                    primary: "#007bff",
                                                },
                                            })}
                                        />
                                    </CCol>
                                </>
                            }

                            {/* Select Size Upgrade Addon */}
                            {
                                selectedCheckbox === null || selectedCheckbox === "isSizeUpgradeCoupon" &&
                                <CCol md="3">
                                    <CFormLabel className="fw-semibold">
                                        Select Size Upgrade Addon <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormSelect
                                        name="sizeUpgradeAddon"
                                        disabled={selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon" || selectedCheckbox === "isComboCoupon"}
                                        value={couponData.sizeUpgradeAddon}
                                        onChange={(e) => setCouponData((prev) => ({
                                            ...prev,
                                            sizeUpgradeAddon: e.target.value,
                                        }))}
                                    >
                                        <option value="">Select Size Upgrade Addon</option>
                                        <option key={updatedFilteredAddons[0]?.[0]?.addonId} value={updatedFilteredAddons[0]?.[0]?.addonId}>
                                            {updatedFilteredAddons[0]?.[0]?.addonTitle}
                                        </option>
                                    </CFormSelect>
                                </CCol>
                            }
                            {/* Hide Coupon ->>> common field for all coupons type */}
                            <CCol md="3">
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={couponData.isHidden}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </CCol>

                            {
                                selectedCheckbox === null || selectedCheckbox === 'isComboCoupon' && <CCol md={3}>
                                    <CFormLabel className="fw-semibold">
                                        Max Addons Allowed
                                    </CFormLabel>
                                    <CFormInput
                                        min={0}
                                        placeholder="Max Addons Allowed"
                                        className="no-arrows"
                                        type="number"
                                        name="maxAddonsAllowed"
                                        value={couponData.maxAddonsAllowed || null}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                handleInputChange(e);
                                            }
                                        }}
                                    />
                                </CCol>
                            }

                            {/* Discount Percent Input field and Max. Dis. Amount Input field */}
                            {
                                (selectedCheckbox === null || selectedCheckbox === 'isUniversalCoupon' || selectedCheckbox === 'isCartCoupon' || selectedCheckbox === 'isProductCoupon' || selectedCheckbox === 'isCategoryCoupon' || selectedCheckbox === 'isComboCoupon') && (
                                    <>
                                        <CCol md={3} className='mb-3'>
                                            <CFormLabel className="fw-semibold">
                                                Discount Percent <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                min={0}
                                                max={100}
                                                disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon" || couponData.discountAmount !== "" || couponData.discountAmount || couponData.discountPrice}
                                                placeholder="Discount Percent"
                                                className="no-arrows"
                                                type="number"
                                                name="discountPercent"
                                                value={couponData.discountPercent || ""}
                                                onChange={handleInputChange}
                                            />
                                        </CCol>
                                        <CCol md={3}>
                                            <CFormLabel className="fw-semibold">
                                                Max. Dis. Amount
                                            </CFormLabel>
                                            <CFormInput
                                                min={0}
                                                disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon" || couponData.discountAmount !== "" || couponData.discountPrice}
                                                placeholder="Max. Dis. Amount"
                                                type="number"
                                                className="no-arrows"
                                                name="maxDiscountAmount"
                                                value={couponData.maxDiscountAmount}
                                                onChange={handleInputChange}
                                            />
                                        </CCol>

                                    </>
                                )
                            }

                            {/* Discount Amount Input field */}
                            {
                                (selectedCheckbox === null || selectedCheckbox === 'isCartCoupon' || selectedCheckbox === 'isProductCoupon' || selectedCheckbox === 'isCategoryCoupon' || selectedCheckbox === 'isComboCoupon') && (
                                    <CCol md={3}>
                                        <CFormLabel className="fw-semibold">
                                            Discount Amount <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            min={0}
                                            step={0.01}
                                            disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSpecialProductCoupon" || couponData.discountPercent !== "" || couponData.discountPrice }
                                            placeholder="Discount Amount"
                                            className="no-arrows"
                                            type="number"
                                            name="discountAmount"
                                            value={couponData.discountAmount || ""}
                                            onChange={handleInputChange}
                                        />
                                    </CCol>
                                )
                            }


                            {/* Min. Order Amount Input field */}
                            <CCol md={3} lg={3} className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Min. Order Amount {
                                        selectedCheckbox === 'isCartCoupon' ? <span className="text-danger">*</span> : null
                                    }
                                </CFormLabel>
                                <CFormInput
                                    min={0}
                                    // disabled={selectedCheckbox === "isBogoCoupon"}
                                    placeholder="Min. Order Amount"
                                    type="number"
                                    className="no-arrows"
                                    name="minOrderAmount"
                                    value={couponData.minOrderAmount}
                                    onChange={handleInputChange}
                                />
                            </CCol>

                            <CCol md="3">
                                <CFormLabel className="fw-semibold">
                                    Is Mrp Coupon? <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isMrpDiscount"
                                    value={couponData.isMrpDiscount}
                                    onChange={handleInputChange}
                                    disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSpecialProductCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || !couponData.discountAmount === "" || selectedCheckbox === "isComboCoupon"}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </CCol>

                            {/* Discount Price Input field* and Applicable Quantity Type Input field */}
                            {
                                (
                                    selectedCheckbox === null || selectedCheckbox === 'isSpecialCategoryCoupon' || selectedCheckbox === 'isSpecialProductCoupon' || selectedCheckbox === 'isComboCoupon'
                                ) && (
                                    <>
                                        <CCol md={3} className='mb-3'>
                                            <CFormLabel className="fw-semibold ">
                                                Discount Price
                                            </CFormLabel>
                                            <CFormInput
                                                min={0}
                                                step={0.01}
                                                disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || couponData.discountAmount || couponData.discountPercent}
                                                placeholder="Discount Price"
                                                className="no-arrows"
                                                type="number"
                                                name="discountPrice"
                                                value={couponData.discountPrice || ""}
                                                onChange={handleInputChange}
                                            />
                                        </CCol>

                                        <CCol md={3} className='mb-3'>
                                            <div>
                                                <CFormLabel className="fw-semibold">
                                                    Applicable Quantity Type
                                                </CFormLabel>
                                                <CFormSelect
                                                    name="applicableQuantityType"
                                                    value={couponData.applicableQuantityType || ""}
                                                    onChange={handleInputChange}
                                                    disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || couponData.discountAmount || couponData.discountPercent}
                                                >
                                                    <option value="">Select Applicable Quantity Type</option>
                                                    <option value="SINGLE">SINGLE</option>
                                                    {
                                                        selectedCheckbox !== 'isComboCoupon' ? (<option value="MULTIPLE">MULTIPLE</option>) : null
                                                    }
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                    </>
                                )
                            }

                            {/* Description Input field */}
                            <CCol md={12} className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Description <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormTextarea
                                    rows={2}
                                    placeholder="Description"
                                    name="description"
                                    value={couponData.description || ""}
                                    onChange={handleInputChange}
                                />
                            </CCol>
                            {/* Terms and Conditions Input field  */}
                            <CCol md="12" className="mb-3">
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Terms and Conditions <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <div className="d-flex mb-2">
                                        <CFormInput
                                            placeholder="Add a term"
                                            value={currentTerm}
                                            onChange={(e) => setCurrentTerm(e.target.value)}
                                            className="me-2"
                                        />
                                        <CButton
                                            className='btn-success'
                                            disabled={!currentTerm.trim()}
                                            onClick={addTerm}
                                        >ADD</CButton>
                                    </div>
                                    {couponData?.termsAndConditions?.length > 0 && (
                                        <ul className="list-group">
                                            {couponData?.termsAndConditions?.map((term, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item d-flex justify-content-between align-items-center"
                                                >
                                                    {term}
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger rounded-pill"
                                                        onClick={() => removeTerm(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        <PrimaryButton style={{ width: '20%' }} title={"ADD COUPON"} isLoading={addBtnLoading} />
                    </CCardFooter>
                </CForm>
            </CCard>
        </CContainer>
    )
};

export default CreateCoupon
