import { CButton, CCol, CFormLabel, CFormSelect, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from "react-datepicker";
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import Select from 'react-select';

const AddonWiseReport = () => {

    const accessToken = localStorage.getItem('authToken');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState('');
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedAddonTitle, setSelectedAddonTitle] = useState("");
    const [selectedAddonValue, setSelectedAddonValue] = useState("");
    const [productList, setProductList] = useState([]);
    const [AddonTitleList, setAddonTitleList] = useState([]);
    const [addonValueList, setAddonValueList] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading2, setloading2] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [productData, setProductData] = useState({});
    const [filteredProductList, setFilteredProductList] = useState([]);
    const [addonData, setAddonData] = useState({});
    const [addonDataValue, setaddonDataValue] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [outlets, setOutlets] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const getOutlets = async () => {
        try {
            const headers = {
            Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("outlet", { headers });
    
            if (response?.data?.status === true) {
            const activeOutlets = response?.data?.data?.outlets.filter((active) => active?.status === "ACTIVE");
            setOutlets(activeOutlets);
            }
        } catch (error) {
            if (error?.response) {
            let errorMessage = "";
            if (error.response?.status === 406) {
                errorMessage =
                error?.response?.data?.error?.message ||
                error?.response?.data?.error;
            } else if (error.response?.status === 412) {
                errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error;
            }
    
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
            }
        }
    };

    const getCategoryList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });

            if (response?.data?.status === true) {
                setCategoryList(response?.data?.data?.categories);
                console.log("Categories :", response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // const getAddonsReport = async (startDate, endDate, selectedProduct, selectedAddonTitle, selectedAddonValue, outlet) => {
    //     try {
    //         setloading2(true);
    //         const headers = {
    //             Authorization: `Bearer ${accessToken}`
    //         };

    //         // Format the dates
    //         const updatedStartDate = startDate ? startDate.toISOString().split("T")[0] : null;
    //         const updatedEndDate = endDate ? endDate.toISOString().split("T")[0] : null;

    //         // Build the body object dynamically
    //         const body = {
    //             productId: selectedProduct,
    //             addonId: selectedAddonTitle,
    //             startDate: updatedStartDate,
    //             endDate: updatedEndDate
    //         }

    //         // Only include selectedAddonValue if it exists
    //         if (selectedAddonValue) {
    //             body.selectedAddonValue = selectedAddonValue;
    //         }
    //         const response = await axios.post("order/sales/new-product-addons-salesReport", body, { headers })

    //         console.log("API response :", response?.data?.data);

    //         // Show success toast
    //         toast.success(response?.data?.data?.message, {
    //             position: "top-right",
    //             autoClose: 3000,
    //             theme: "colored"
    //         });

    //         // Set the report list or update state as required
    //         setReportList(response?.data?.data?.orderedProducts || []);
    //         setProductData(response?.data?.data?.product);
    //         setAddonData(response?.data?.data?.addon);
    //         setaddonDataValue(response?.data?.data);
    //     } catch (error) {
    //         if (error?.response) {
    //             let errorMessage = '';
    //             if (error.response?.status === 406) {
    //                 errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
    //             } else if (error.response?.status === 412) {
    //                 errorMessage = error?.response?.data?.data?.message;
    //             } else if (error.response?.status === 500) {
    //                 errorMessage = error?.response?.data?.error;
    //             }
    //             toast.error(errorMessage, {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 theme: "colored",
    //             });
    //         }
    //     } finally {
    //         setloading2(false);
    //     }
    // };

    const getAddonsReport = async (startDate, endDate, selectedProduct, selectedAddonTitle, selectedAddonValue, outlet) => {
        try {
            setloading2(true);
            const headers = {
                Authorization: `Bearer ${accessToken}`
            };
    
            // Format the dates
            const updatedStartDate = startDate ? startDate.toISOString().split("T")[0] : null;
            const updatedEndDate = endDate ? endDate.toISOString().split("T")[0] : null;
    
            // Build the body object dynamically
            const body = {
                productId: selectedProduct,
                addonId: selectedAddonTitle,
                startDate: updatedStartDate,
                endDate: updatedEndDate
            };
    
            // Only include selectedAddonValue if it exists
            if (selectedAddonValue) {
                body.selectedAddonValue = selectedAddonValue;
            }
    
            // Only include outletId if outlet is present
            if (outlet) {
                body.outletId = outlet;
            }
    
            const response = await axios.post("order/sales/new-product-addons-salesReport", body, { headers });
    
            console.log("API response :", response?.data?.data);
    
            // Show success toast
            toast.success(response?.data?.data?.message, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored"
            });
    
            // Set the report list or update state as required
            setReportList(response?.data?.data?.orderedProducts || []);
            setProductData(response?.data?.data?.product);
            setAddonData(response?.data?.data?.addon);
            setaddonDataValue(response?.data?.data);
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (error.response?.status === 406) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setloading2(false);
        }
    };

    const getProductList = async () => {
        try {
            const response = await axios.get("order/sales/all-products-addons");
            console.log(" Product List Response :", response?.data?.data?.orderProducts);
            setProductList(response?.data?.data?.orderProducts)
        } catch (error) {
            console.log("error :", error);
        }
    }

    const handleEndDateChange = (date) => {
        if (date && startDate && date < startDate) {
            setError('End date cannot be earlier than start date');
        } else {
            setError('');
            setEndDate(date);
        }
    };

    // const handleProductChange = async(productId) => {
    //     console.log("productId", productId);
    //     setSelectedProduct(productId);
    //     const selectedData = productList.find((fi) => { 
    //         const chosenIItem = fi.addons.find(addon=>addon.product.toString()===productId.toString());
    //         console.log("product Choosen Item :", fi);

    //         if(chosenIItem){
    //             return true;
    //         }
    //         else{
    //             return false;
    //         }
    //     })
    //     console.log("selectedData", selectedData);      
    //     setAddonTitleList(selectedData?.addons);
    // }

    const handleProductChange = async (productId) => {
        console.log("productId", productId);
        setSelectedProduct(productId);

        const selectedData = productList?.find(fi =>
            fi?.addons?.some(addon => addon?.product?.toString() === productId?.toString())
        );

        console.log("selectedData", selectedData);
        setAddonTitleList(selectedData?.addons || []);
    };

    const handleAddonTitleChange = async (addonTitleId) => {
        console.log("Selected Addon Title Id:", addonTitleId);
        setSelectedAddonTitle(addonTitleId);

        // Find the selected addon title in the AddonTitleList based on the provided ID
        const selectedAddonTitle = AddonTitleList.find((addon) => addon._id === addonTitleId);

        if (selectedAddonTitle) {
            console.log("Selected Addon Title:", selectedAddonTitle);

            // Extract the addon values from the selected addon title
            const addonValues = selectedAddonTitle.addonValues;

            console.log("Addon Values for the selected title:", addonValues);

            setAddonValueList(addonValues);  // Example of setting the addon values in a state if needed
        } else {
            console.log("Addon Title not found.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setClicked(true);
        setBtnLoading(true);
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day

        const formatDate = (date) => {
            return date.toISOString().split('T')[0]; // Format: '2024-07-31'
        };

        await getAddonsReport(adjustedStartDate, adjustedEndDate, selectedProduct, selectedAddonTitle, selectedAddonValue, selectedOutlet);
        // toast.success("Data Fetched Successfully", {
        //     position: "top-right",
        //     autoClose: 3000,
        //     theme: "colored",
        // });
        setBtnLoading(false);
    };

    const handlePrintReport = () => {
        const formatDate = (date) => {
            return new Date(date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        };

        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        const tableContent = `
          <table style="border-collapse: collapse; width: 100%;">
            <thead>
              <tr>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Date</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Product Name</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Addon Title</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Addon Value</th>
                <th style="border: 1px solid black; padding: 8px; text-align: right;">Sales Count</th>
              </tr>
            </thead>
            <tbody>
              ${reportList?.length > 0 ?
                reportList?.map((report, index) => `
                  <tr style="font-size: 13px">
                    <td style="border: 1px solid black; padding: 8px;">${index + 1}</td>
                    <td style="border: 1px solid black; padding: 8px;">${new Date(report?.date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                    <td style="border: 1px solid black; padding: 8px;">${productData?.name}</td>
                    <td style="border: 1px solid black; padding: 8px;">${addonData?.addonTitle || 'N/A'}</td>
                    <td style="border: 1px solid black; padding: 8px;">${report?.selectedAddonValue || report?.addon?.dependentValue || 'Includes All Addon Values'}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: right">${report?.totalQuantity || 0}</td>
                  </tr>
                `).join("") + `
                <tr style="font-weight: bold;">
                    <td colspan="5" style="border: 1px solid black; padding: 8px; text-align: right; font-size: 12px">Total Count</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: right; font-size: 12px"> ${reportList?.reduce((totalSales, rep) => totalSales + rep?.totalQuantity, 0)}</td>
                </tr>` :
                `<tr>
                  <td colspan="6" style="border: 1px solid black; padding: 8px; text-align: center;">No Addon Reports Found</td>
                </tr>`
            }
            </tbody>
          </table>
        `;

        const win = window.open("", "", "width=900,height=600");
        win.document.write(`
          <html>
            <head>
              <title>Addon Wise Report</title>
              <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body onload="window.print();window.close()">
                <div style="display: flex; justify-content: space-between; align-items: center">
                    <h2>Addon Wise Report</h2>
                    <span>From [${formattedStartDate} to ${formattedEndDate}]</span>
                </div>
              <div>${tableContent}</div>
            </body>
          </html>
        `);
        win.document.close();
    };

    const handleCategoryChange = (selectedCategoryId) => {
        setCategoryId(selectedCategoryId);
        console.log("Selcted Category ID :", selectedCategoryId);
        
        if (selectedCategoryId === "") {
            // If no category is selected, display all products
            setFilteredProductList(productList);
        } else {
            // Filter products by category
            console.log("Products List :", productList);
            const filteredProducts = productList.filter(
                // (prod) => prod?.product?.category === selectedCategoryId
                (prod) => prod?.category === selectedCategoryId
            );
            console.log("FIltered Products :", filteredProducts);
            
            setFilteredProductList(filteredProducts);
        }
    };

    useEffect(() => {
        getProductList();
    }, []);

    useEffect(() => {
        const now = new Date();
        const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const currentDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );

        const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
        const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
        // Set the start date and end date
        setStartDate(start);
        setEndDate(end);
    }, []);

    useEffect(() => {
        getCategoryList();
        getOutlets();
    }, [])

    return (
        <div className='container'>
            <CRow>
                <h5>ADDON WISE SALES REPORT</h5>
            </CRow>
            <CRow className='mt-3'>
                
                <CCol md={3} lg={3}>
                    <CFormLabel className="fw-semibold">Select Category {!selectedCategory ? <span className='text-danger'>{"("}Please Select Category{")"}</span> : ""}</CFormLabel>
                    <Select
                        options={categoryList.map((category) => ({
                            value: category._id,
                            label: category.name,
                        }))}
                        value={
                            categoryId
                                ? { value: categoryId, label: categoryList.find((cat) => cat._id === categoryId)?.name }
                                : null
                        }
                        onChange={(selectedOption) => {
                                setSelectedCategory(selectedOption);
                                handleCategoryChange(selectedOption ? selectedOption.value : '')
                            }
                        }
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Select Category"
                    />

                </CCol>
                <CCol md={3} lg={3}>
                    <CFormLabel className='fw-semibold'>Products {!selectedProduct ? <span className='text-danger'>{"("}Please Select Product{")"}</span> : ""} </CFormLabel>
                    <Select
                        options={filteredProductList.map((prod) => ({
                            value: prod?._id,
                            label: prod?.name,
                        }))}
                        isDisabled={!selectedCategory}
                        value={
                            selectedProduct
                                ? { value: selectedProduct, label: filteredProductList.find(prod => prod?._id === selectedProduct)?.name }
                                : null
                        }
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                handleProductChange(selectedOption.value);
                            } else {
                                handleProductChange(''); // Clear the selection
                            }
                        }}
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Select Products"
                    />
                </CCol>
                <CCol md={3} lg={3}>
                    <CFormLabel className='fw-semibold'>Addon Title {!selectedAddonTitle ? <span className='text-danger'>{"("}Please Select Title{")"}</span> : ""}</CFormLabel>
                    <CFormSelect id='selectField' disabled={!selectedProduct} value={selectedAddonTitle} onChange={(e) => handleAddonTitleChange(e.target.value)}>
                        <option value="">Select Addon Title</option>
                        {AddonTitleList?.length > 0 && AddonTitleList?.map((add, index) => (
                            <option key={index} value={add?._id}>
                                {add?.addonTitle}
                            </option>
                        ))}
                    </CFormSelect>
                </CCol>
                <CCol md={3} lg={3}>
                    <CFormLabel className='fw-semibold'>Addon Value</CFormLabel>
                    <CFormSelect id='selectField' value={selectedAddonValue} onChange={(e) => setSelectedAddonValue(e.target.value)}>
                        <option value="">Select Addon Value</option>
                        {addonValueList.map((addvalue, index) => (
                            <option key={index} value={addvalue.value}>
                                {addvalue.value}
                            </option>
                        ))}
                    </CFormSelect>
                </CCol>
            </CRow>

            <CRow className='mt-2'>
                <CCol md={3} lg={3}>
                    <CFormLabel className="fw-semibold">Select Outlet</CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => {
                            const newOutlet = e.target.value;
                            setSelectedOutlet(newOutlet);
                            // getOutletName(newOutlet);
                        }}
                    >
                        <option value="">All Outlets</option>

                        {outlets?.map((outName, index) => {
                        return (
                            <option key={index} value={outName?._id}>
                            {outName?.name}
                            </option>
                        );
                        })}
                    </CFormSelect>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Start Date</CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={startDate}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Select Start Date"
                            className="form-control"
                        />
                    </div>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>End Date</CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={endDate}
                            // onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={handleEndDateChange}
                            placeholderText="Select End Date"
                            className="form-control"
                        />
                    </div>
                </CCol>
                <CCol md={2}>
                    <CRow><CFormLabel className='text-white'>.</CFormLabel></CRow>
                    <CButton color="primary" className="w-100" onClick={handleSubmit}
                        disabled={!(selectedCategory && selectedProduct && selectedAddonTitle)}
                    >
                        {btnLoading ? <CSpinner size="sm" /> : 'Submit'}
                        {/* Submit */}
                    </CButton>
                </CCol>
                <CCol md={3}>
                    <CRow><CFormLabel className='text-white'>.</CFormLabel></CRow>
                    <CButton color="success" className="w-100 rounded-pill" onClick={handlePrintReport}
                        disabled={btnLoading}
                    >
                        {btnLoading ? <CSpinner size="sm" /> : 'Print Report'}
                        {/* Submit */}
                    </CButton>
                </CCol>
            </CRow>

            <CRow className="mt-4">
                <CCol>
                    {clicked === false ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                            <h5 className="text-danger">Please Select Category, Product and Addon Title</h5>
                        </div>
                    ) : (
                        <CTable striped bordered hover responsive>
                            <CTableHead>
                                <CTableRow style={{ fontSize: '15px' }}>
                                    <CTableHeaderCell>Sr no</CTableHeaderCell>
                                    <CTableHeaderCell>Date</CTableHeaderCell>
                                    <CTableHeaderCell>Product Name</CTableHeaderCell>
                                    <CTableHeaderCell>Addon Title</CTableHeaderCell>
                                    <CTableHeaderCell>Addon Value</CTableHeaderCell>
                                    <CTableHeaderCell>Sales Count</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {loading2 ? (
                                    <CTableRow>
                                        <CTableDataCell colSpan="6" className="text-center">
                                            <CSpinner color="primary" size="lg" />
                                        </CTableDataCell>
                                    </CTableRow>
                                ) : (
                                    reportList?.length > 0 ? (
                                        <>
                                            {reportList.map((report, index) => (
                                                <CTableRow key={index} style={{ fontSize: '15px' }}>
                                                    <CTableDataCell>{index + 1}</CTableDataCell>
                                                    <CTableDataCell>
                                                        {new Date(report?.date).toLocaleDateString('en-US', {
                                                            day: 'numeric',
                                                            month: 'long',
                                                            year: 'numeric'
                                                        })}
                                                    </CTableDataCell>
                                                    <CTableDataCell>{productData?.name}</CTableDataCell>
                                                    <CTableDataCell>{addonData?.addonTitle || 'N/A'}</CTableDataCell>
                                                    <CTableDataCell style={{ width: '19%' }}>{addonDataValue?.selectedAddonValue || report?.addon?.dependentValue || 'Includes All Addon Values'}</CTableDataCell>
                                                    <CTableDataCell className="text-end" style={{ width: '11%' }}>{report?.totalQuantity || 0}</CTableDataCell>
                                                </CTableRow>
                                            ))}
                                            {/* Calculate total sales */}
                                            <CTableRow className="fw-bold">
                                                <CTableDataCell colSpan="5" className="text-end">Total Count</CTableDataCell>
                                                <CTableDataCell className="text-end">
                                                    {reportList?.reduce((totalSales, rep) => totalSales + rep?.totalQuantity, 0)}
                                                </CTableDataCell>
                                            </CTableRow>
                                        </>
                                    ) : (
                                        <CTableRow>
                                            <CTableDataCell colSpan="6" className="text-center text-danger fw-5">
                                                No Data Found
                                            </CTableDataCell>
                                        </CTableRow>
                                    )
                                )}
                            </CTableBody>
                        </CTable>
                    )
                    }
                </CCol>
            </CRow>
        </div>
    )
}

export default AddonWiseReport