import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import axios from '../../api/axios';
// @ts-ignore
import debounce from 'lodash.debounce';
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CPagination, CPaginationItem, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import Select from 'react-select';


const UserCouponReport = () => {

    const accessToken = localStorage.getItem("authToken");
    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState('25');
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [cachedUsersList, setCachedUsersList] = useState([]); // Cached list
    const [userCouponData, setUserCouponData] = useState([]);
    const [totalPages, setTotalPages] = useState('');
    const [printBtnLoading, setPrintBtnLoading] = useState(false);

    const getCustomerData = async (query) => {
        try {
            const headers = { Authorization: `Bearer ${accessToken}` };
            const url = `user/getUsersWithOrders?search=${query}`;
            const response = await axios.get(url, { headers });
            setUsersList(response?.data?.data?.usersWithOrders || []);
            setCachedUsersList(response?.data?.data?.usersWithOrders || []); // Cache the user list
        } catch (error) {
            let errorMessage = 'An error occurred';
            if (error.response?.status === 406) {
                errorMessage = error.response?.data?.error?.message || error.response?.data?.error;
            } else if (error.response?.status === 412) {
                errorMessage = error.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
                errorMessage = error.response?.data?.error;
            }

            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        }
    };

    const handleSearchChange = debounce((query) => {
        if (query.trim().length > 0) {
            getCustomerData(query);
        }
    }, 500);

    const getUserCouponReport = async (page, limit, selectedUser) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            console.log("Inside Get Selected User :", selectedUser);
            
            let url = `order/coupons-used-by-user?page=${page}&limit=${limit}&user_id=${selectedUser}`
            const response = await axios.get(url, { headers });
            setUserCouponData(response?.data?.data?.data)
            setTotalPages(response?.data?.data?.pagination?.total_pages);
            toast.success("Data Fetched Successfully", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        } catch (error) {
            let errorMessage = 'An error occurred';
            if (error.response?.status === 406) {
                errorMessage = error.response?.data?.error?.message || error.response?.data?.error;
            } else if (error.response?.status === 412) {
                errorMessage = error.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
                errorMessage = error.response?.data?.error;
            }

            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        }
    }

    const handlePageChange = (page) => {

        if (page > 0 && page <= totalPages) {
        setCurrentPage(page);
        }

        getUserCouponReport(page, recordsPerPage, selectedUser);
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setSubmitBtnLoading(true);
        console.log("Selected User :", selectedUser);
        
        await getUserCouponReport(currentPage, recordsPerPage, selectedUser);
        setSubmitBtnLoading(false);
    }

    const handlePrintReport = async() => {

        const tableContent = `
          <table style="border-collapse: collapse; width: 100%;">
            <thead>
              <tr style="font-size: 12px">
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Customer Name</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Contact Details</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Ordered Date</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Order No</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Coupon Code</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Discount Amount</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Grand Total</th>
              </tr>
            </thead>
            <tbody>
              ${userCouponData?.length > 0 ? 
                userCouponData?.map((customerInfo, index) => `
                  <tr style="font-size: 11px">
                    <td style="border: 1px solid black; padding: 8px; ">${index + 1}</td>
                    <td style="border: 1px solid black; padding: 8px;">${customerInfo?.user?.name}</td>
                    <td style="border: 1px solid black; padding: 8px;">${customerInfo?.user?.email} <br /> ${customerInfo?.user?.mobile}</td>
                    <td style="border: 1px solid black; padding: 8px;">${customerInfo?.orderDate}</td>
                    <td style="border: 1px solid black; padding: 8px;">${customerInfo?.order_sequence}${customerInfo?.orderNo}</td>
                    <td style="border: 1px solid black; padding: 8px;">${customerInfo?.couponCode}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end">₹. ${customerInfo?.discountAmount?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end">₹. ${customerInfo?.grandTotal?.toFixed(2)}</td>
                  </tr>
                `).join("") :
                `<tr>
                  <td colspan="8" style="border: 1px solid black; padding: 8px; text-align: center;">No Customers Found</td>
                </tr>`
              }
            </tbody>
          </table>
        `;
    
        const win = window.open("", "", "width=900,height=600");
        win.document.write(`
          <html>
            <head>
              <title>USER COUPON REPORT</title>
              <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body onload="window.print();window.close()">
              <h2>USER COUPON REPORT</h2>
              <div>${tableContent}</div>
            </body>
          </html>
        `);
        win.document.close();
    }


    return (
        <div className='container'>
            <h5>USER COUPON REPORT</h5>
            <CRow className='mt-3'>
                <CCol md="4" lg="4">
                    <CFormLabel className='fw-semibold'>Select User</CFormLabel>
                    <Select
                        options={usersList.map((user) => ({
                            value: user._id,
                            label: user.name,
                        }))}
                        value={
                            selectedUser
                                ? { value: selectedUser, label: cachedUsersList.find(user => user._id === selectedUser)?.name }
                                : null
                        }
                        onInputChange={(query) => {
                            setSearchTerm(query);
                            handleSearchChange(query);
                        }}
                        onChange={(selectedOption) => {
                            setSelectedUser(selectedOption ? selectedOption.value : null);
                        }}
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Search and Select User"
                    />
                </CCol>
                
                <CCol md="2" lg="2">
                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                        <CFormLabel className='text-white'>.</CFormLabel>
                        <CButton onClick={handleSubmit}>
                            {submitBtnLoading ? <CSpinner size='sm' /> : "Sumbit"}
                        </CButton>
                    </div>
                </CCol>

                <CCol md="2" lg="2" className="text-center">
                    <CFormLabel className="fw-semibold text-white">.</CFormLabel>
                    <CButton className="btn rounded-pill w-100 bg-success border-success" disabled={printBtnLoading} onClick={handlePrintReport}>
                        {printBtnLoading ? <CSpinner size="sm" /> : "Print Report"}
                    </CButton>
                </CCol>

            </CRow>

            <CRow className="mt-3">
                <CCol>
                    {/* Dropdown for selecting records per page */}
                    <CRow>
                        <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                            <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                <CFormSelect
                                className="ml-3"
                                style={{ width: 'auto' }}
                                value={recordsPerPage}
                                onChange={(e) => {
                                    const selectedRecordsPerPage = e.target.value;
                                    if (selectedRecordsPerPage !== recordsPerPage) { // Only proceed if there's a change
                                        setRecordsPerPage(selectedRecordsPerPage);
                                        getUserCouponReport(currentPage, selectedRecordsPerPage, selectedUser);
                                    }
                                }}
                                >
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    <option value={300}>300</option>
                                    <option value={400}>400</option>
                                    <option value={500}>500</option>
                                </CFormSelect>
                            </div>
                            <CPaginationItem
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                Previous
                            </CPaginationItem>
                            {totalPages > 15 ? (
                                <>
                                    {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                    {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                                    {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                    const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                                    // Avoid rendering out-of-range page numbers
                                    if (pageNumber <= totalPages) {
                                        return (
                                        <CPaginationItem
                                            key={pageNumber}
                                            active={pageNumber === currentPage}
                                            onClick={() => handlePageChange(pageNumber)}
                                        >
                                            {pageNumber}
                                        </CPaginationItem>
                                        );
                                    }
                                    return null;
                                    })}

                                    {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                    {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                </>
                                ) : (
                                // If totalPages is 15 or less, just display all page numbers
                                Array.from({ length: totalPages }, (_, i) => (
                                    <CPaginationItem
                                    key={i + 1}
                                    active={i + 1 === currentPage}
                                    onClick={() => handlePageChange(i + 1)}
                                    >
                                    {i + 1}
                                    </CPaginationItem>
                                ))
                                )
                            }
                            <CPaginationItem
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                Next
                            </CPaginationItem>
                        </CPagination>
                    </CRow>

                    
                    <CTable striped bordered hover responsive>
                        <CTableHead>
                            <CTableRow style={{ fontSize: '15px' }}>
                            <CTableHeaderCell>#</CTableHeaderCell>
                            <CTableHeaderCell>Customer Name</CTableHeaderCell>
                            <CTableHeaderCell>Contact Details</CTableHeaderCell>
                            <CTableHeaderCell>Ordered Date</CTableHeaderCell>
                            <CTableHeaderCell>Order No</CTableHeaderCell>
                            <CTableHeaderCell>Coupon Code</CTableHeaderCell>
                            <CTableHeaderCell>Discount Amount</CTableHeaderCell>
                            <CTableHeaderCell>Grand Total</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {loading ? (
                                <CTableRow>
                                    <CTableDataCell colSpan="10" className="text-center">
                                    <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                                    </CTableDataCell>
                                </CTableRow>
                            ) :
                            userCouponData?.length > 0 ? (
                                <>
                                    {userCouponData?.map((customerData, index) => (
                                    <CTableRow key={index} style={{ fontSize: '15px' }}>
                                        <CTableDataCell>{index+1}</CTableDataCell>
                                        <CTableDataCell>{customerData?.user?.name}</CTableDataCell>
                                        <CTableDataCell className='text-primary'><span>{customerData?.user?.email}</span> <br /> <span>{customerData?.user?.mobile}</span> </CTableDataCell>
                                        <CTableDataCell>{customerData?.orderDate}</CTableDataCell>
                                        <CTableDataCell>{customerData?.order_sequence}{customerData?.orderNo}</CTableDataCell>
                                        <CTableDataCell className='fw-semibold text-center text-success'>{customerData?.couponCode}</CTableDataCell>
                                        <CTableDataCell className='text-end'>₹. {customerData?.discountAmount?.toFixed(2)}</CTableDataCell>
                                        <CTableDataCell className='text-end'>₹. {customerData?.grandTotal?.toFixed(2)}</CTableDataCell>
                                    </CTableRow>
                                    ))}
                                </>
                            ) : (
                                <CTableRow>
                                    <CTableDataCell colSpan="9" className="text-center text-danger fw-5">
                                    No Data Found
                                    </CTableDataCell>
                                </CTableRow>
                            )}
                        </CTableBody>
                    </CTable>
                    <CRow>
                        <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                        <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                            <CFormSelect
                            className="ml-3"
                            style={{ width: 'auto' }}
                            value={recordsPerPage}
                            onChange={(e) => setRecordsPerPage(e.target.value)}
                            >
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={300}>300</option>
                            <option value={400}>400</option>
                            <option value={500}>500</option>
                            </CFormSelect>
                        </div>
                        <CPaginationItem
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </CPaginationItem>
                        {totalPages > 15 ? (
                            <>
                                {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                                {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                                // Avoid rendering out-of-range page numbers
                                if (pageNumber <= totalPages) {
                                    return (
                                    <CPaginationItem
                                        key={pageNumber}
                                        active={pageNumber === currentPage}
                                        onClick={() => handlePageChange(pageNumber)}
                                    >
                                        {pageNumber}
                                    </CPaginationItem>
                                    );
                                }
                                return null;
                                })}

                                {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                            </>
                            ) : (
                            // If totalPages is 15 or less, just display all page numbers
                            Array.from({ length: totalPages }, (_, i) => (
                                <CPaginationItem
                                key={i + 1}
                                active={i + 1 === currentPage}
                                onClick={() => handlePageChange(i + 1)}
                                >
                                {i + 1}
                                </CPaginationItem>
                            ))
                            )
                        }
                        <CPaginationItem
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </CPaginationItem>
                        </CPagination>
                    </CRow>
                </CCol>
            </CRow>
            
        </div>
    )
}

export default UserCouponReport