import { CBadge, CButton, CCol, CFormLabel, CFormSelect, CPagination, CPaginationItem, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import ReactDatePicker from "react-datepicker";
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { Spinner } from 'react-bootstrap';

const ItemWiseReport = () => {

    const accessToken = localStorage.getItem("authToken");
    const [outlets, setOutlets] = useState([]);
    const animatedComponents = makeAnimated();
    const [selectedOutlet, setSelectedOutlet] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [error, setError] = useState('');
    const [clicked, setClicked] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [productList, setProductList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isPrintBtnLoading, setIsPrintBtnLoading] = useState(false);
    const [productId, setProductId] = useState('');
    const [productsList, setProductsList] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(productsList);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState('');
    const [totalRecords, setTotalRecords] = useState('');
    const [recordsPerPage, setRecordsPerPage] = useState('25');
    const [totals, setTotals] = useState({});

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: "6px",
            minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d",
        }),
    };

    const handlePageChange = (page) => {
        console.log("page :", page);
        
        if (page > 0 && page <= totalPages) {
          setCurrentPage(page);
        }
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
        getItemWiseReport(adjustedStartDate, adjustedEndDate, selectedOutlet, selectedCategory, productId, page, recordsPerPage);
    };

    const getOutlets = async () => {
        try {
            const headers = {
            Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("outlet", { headers });
    
            if (response?.data?.status === true) {
                const filteredOutlets = response?.data?.data?.outlets.filter((act) => act?.status === "ACTIVE");
                console.log("Filtered Outlets :", filteredOutlets);
                setOutlets(filteredOutlets);
            }
        } catch (error) {
            if (error?.response) {
            let errorMessage = '';
            if ( error.response?.status === 406 ) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
            }
            else if ( error.response?.status === 412 ) {
                errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error
            }
    
            toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };
    
    const getCategoryList = async () => {
        try {
            const headers = {
            Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });
    
            if (response?.data?.status === true) {
            setCategoryList(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
            }
        }
    };

    const getProductsList = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("product", { headers });
          if (response?.data?.status === true) {
            setProductsList(response?.data?.data?.products);
            setFilteredProducts(response?.data?.data?.products)
          }
        } catch (error) {
          if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };

    const categoryOptions = categoryList.map((category) => ({
        value: category._id,
        label: category.name,
    }));

    const getItemWiseReport = async (startDate, endDate, outlet, category, product, page, limit) => {
        try {
            setLoading2(true);
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
    
            const updatedStartDate = startDate ? startDate.toISOString().split("T")[0] : null;
            const updatedEndDate = endDate ? endDate.toISOString().split("T")[0] : null;
    
            // Construct the query parameters based on the presence of outlet and category
            const queryParams = new URLSearchParams();
            queryParams.append('page', page);
            queryParams.append('limit', limit);
            if (updatedStartDate) queryParams.append('startDate', updatedStartDate);
            if (updatedEndDate) queryParams.append('endDate', updatedEndDate);
            if (outlet) queryParams.append('outletId', outlet);
            if (category) queryParams.append('category', category);
            if (product) queryParams.append('product', product);
    
            const response = await axios.get(`order/getItemWiseOrderReport?${queryParams.toString()}`, { headers });
    
            console.log("Items Sales Response :", response?.data?.data);
            setTotalRecords(response?.data?.data?.pagination?.totalRecords);
            setTotalPages(response?.data?.data?.pagination?.totalPages);
            setProductList(response?.data?.data?.data);
            setFilteredData(response?.data?.data?.data);
            setTotals(response?.data?.data?.totals);
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (error.response?.status === 406) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }
    
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setLoading2(false);
        }
    };

    const handleEndDateChange = (date) => {
        if (date && startDate && date < startDate) {
          setError('End date cannot be earlier than start date');
        } else {
          setError('');
          setEndDate(date);
        }
    };

    const handleSubmit = async() => {
        setClicked(true);
        setBtnLoading(true);
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day

        
        // Log the formatted dates
        console.log("adjusted start :", adjustedStartDate);
        console.log("adjusted End :", adjustedEndDate);
        
        const formatDate = (date) => {
            return date.toISOString().split('T')[0]; // Format: '2024-07-31'
        };

        setCurrentPage(1);

        await getItemWiseReport(adjustedStartDate, adjustedEndDate, selectedOutlet, selectedCategory, productId, 1, recordsPerPage);

        toast.success("Data Fetched Successfully", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
        });
        setBtnLoading(false);
    };

    const handlePrintReport = () => {
        setIsPrintBtnLoading(true);
        const formatDate = (date) => {
            return new Date(date).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            });
          };
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        const mrpRate = productList.reduce((totalTa, product) => totalTa + product?.mrpRate, 0).toFixed(2);
        const rate = productList.reduce((totalTa, product) => totalTa + product?.rate, 0).toFixed(2);
        const addon = productList.reduce((totalTa, product) => totalTa + product?.totalAddonOfferPrice, 0).toFixed(2);
        const quantity = productList.reduce((total, product) => total + product?.quantity, 0);
        const totalDisc = productList.reduce((total, product) => total + product?.discount, 0).toFixed(2);
        const taxable = productList.reduce((total, product) => total + product?.taxable, 0).toFixed(2);
        const tax = productList.reduce((total, product) => total + product?.taxAmount, 0).toFixed(2);
        
        const totalRevenue = productList.reduce((total, product) => total + product?.grand_total, 0).toFixed(2);

        const tableContent = `
            <table style="border-collapse: collapse; width: 100%;">
                <thead>
                <tr style="font-size: 13px">
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 80px">Date</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">#</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 120px">Item</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: center;">Quantity</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">MRP</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">Rate</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">Addon</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">Discount</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">Taxable</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">Tax</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 110px">Total</th>
                </tr>
                </thead>
                <tbody>
                ${productList?.length > 0 ? 
                    productList.map((productInfo, index) => `
                        <tr>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px">${index + 1}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px;">${productInfo?.date}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px;">${productInfo?.order_no}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px;">${productInfo?.category_name} >> ${productInfo?.product_name} 
                                ${productInfo?.orderStatus === "CANCELLED" ? `
                                    <br />
                                    <span style="color: red; font-weight: semi-bold; font-size: 10px;">(CANCELLED)</span>
                                ` : ''}
                            </td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 11px; width: 60px; text-align: center">${productInfo?.quantity}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.mrpRate ? productInfo?.mrpRate?.toFixed(2) : "----"}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.rate ? productInfo?.rate?.toFixed(2) : "----"}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: center">${productInfo?.totalAddonOfferPrice?.toFixed(2)}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.discount?.toFixed(2)}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.taxable?.toFixed(2)}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.taxAmount?.toFixed(2)}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.grand_total?.toFixed(2)}</td>
                        </tr>
                    `).join("") + `
                <tr style="font-weight: bold;">
                    <td colspan="4" style="border: 1px solid black; padding: 8px; text-align: right">Total</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center">${totals?.totalQuantity}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center">${totals?.totalMrpRate?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center">₹ ${totals?.totalRate?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center">₹ ${totals?.totalAddonOfferPrice?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center">₹ ${totals?.totalDiscount?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center">₹ ${totals?.totalTaxable?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center">₹ ${totals?.totalTaxAmount?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: right">₹ ${totals?.totalGrandTotal?.toFixed(2)}</td>
                </tr>` :
                    `<tr>
                        <td colspan="12" style="border: 1px solid black; padding: 8px; text-align: center;">No Data Found</td>
                    </tr>`
                }
                </tbody>
            </table>
        `;

        const win = window.open("", "", "width=900,height=600");
        win.document.write(`
        <html>
            <head>
            <title>Product Wise Sales Report</title>
            <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
            </style>
            </head>
            <body onload="window.print();window.close()">
            <div style="display: flex; justify-content: space-between; align-items: center">
                <h2>Product Wise Sales Report</h2>
                <span>From [${formattedStartDate} to ${formattedEndDate}]</span>
            </div>
            <div>${tableContent}</div>
            <div style="margin-top: 20px; text-align: right; font-size: 15px;">
                
            </div>
            </body>
        </html>
        `);
        win.document.close();
        setIsPrintBtnLoading(false);
    };

    useEffect(() => {
        const now = new Date();
        const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const currentDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
    
        const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
        const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
        // Set the start date and end date
        setStartDate(start);
        setEndDate(end);

    }, []);

    useEffect(() => {
        getCategoryList();
        getOutlets();
        getProductsList();
    }, []);

    useEffect(() => {
        
        if(searchText === "") {
            // getItemWiseReport(startDate, endDate, selectedOutlet, selectedCategory);
        }
    }, [searchText]);

    return (
        <div className='container'>
            <CRow>
                <h5>ITEM WISE SALES REPORTS</h5>
            </CRow>

            <CRow className='mt-3'>
                <CCol md={4}>
                    <CFormLabel className='fw-semibold'>Select Outlet {!selectedOutlet ? <span className='text-danger'>{"("}Please Select Outlet{")"}</span> : ""}</CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => setSelectedOutlet(e.target.value)}
                    >
                        <option value="">Select Outlet</option>

                        {outlets?.map((outName, index) => {
                            return (
                                <option key={index} value={outName?._id}>
                                    {outName?.name}
                                </option>
                            );
                        })}
                    </CFormSelect>
                </CCol>
                <CCol md={4}>
                    <CFormLabel className='fw-semibold'>Select Category {!selectedCategory ? <span className='text-danger'>{"("}Please Select Category{")"}</span> : ""}</CFormLabel>
                    <Select
                        isDisabled={!selectedOutlet}
                        id="selectField"
                        // options={[{ value: '', label: 'All Category' }, ...categoryOptions]}
                        options={categoryOptions}
                        value={categoryOptions?.find(option => option?.value === selectedCategory)}
                        // onChange={(selectedOption) => setSelectedCategory(selectedOption?.value)}
                        onChange={(selectedOption) => {
                            const selectCategory = selectedOption?.value;
                            console.log("selectCategory List :", selectCategory);
                            setSelectedCategory(selectCategory)
                            // setCategoryId(selectCategory);
                            if (selectCategory === undefined) {
                              setFilteredProducts(productsList);
                            } else {
                              setFilteredProducts(
                                productsList.filter((product) => product?.category?._id === selectCategory)
                              );
                            }
                        }}
                        isClearable
                        styles={customStyles}
                        placeholder="Select Category" // You can also use a dynamic placeholder from props or state
                        // closeMenuOnSelect={false}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: "#e9ecef",
                                primary: "#007bff",
                            },
                        })}
                    />
                </CCol>
                <CCol lg={4}>
                    <CFormLabel className="fw-semibold">Select Menu</CFormLabel>
                    <Select
                        isDisabled={!selectedOutlet}
                        options={filteredProducts?.map((rm) => ({
                            value: rm?._id,
                            label: rm?.name,
                        }))}
                        value={
                            productId
                                ? { value: productId, label: filteredProducts?.find((rm) => rm._id === productId)?.name }
                                : null
                        }
                        onChange={(selectedOption) => {
                            setProductId(selectedOption ? selectedOption?.value : '');
                        }}
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Select Menu"
                    />
                </CCol>
                
            </CRow>

            <CRow className='mt-2'>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Start Date</CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={startDate}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Select Start Date"
                            className="form-control"
                        />
                    </div>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>End Date</CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={endDate}
                            // onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={handleEndDateChange}
                            placeholderText="Select End Date"
                            className="form-control"
                        />
                        {/* { error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div> } */}
                    </div>
                </CCol>
                <CCol xs={12} md={4}>
                    <CRow><CFormLabel className='text-white'>.</CFormLabel></CRow>
                    <CButton color="primary" className="w-75" onClick={handleSubmit} 
                        disabled={!(selectedOutlet && selectedCategory)}
                    >
                        {btnLoading ? <CSpinner size="sm" /> : 'Submit'} 
                        {/* Submit */}
                    </CButton>
                </CCol>
                <CCol xs={12} md={3} lg={4}>
                    <CRow><CFormLabel className='text-white'>.</CFormLabel></CRow>

                    <CButton color="success" className="btn rounded-pill w-75" onClick={handlePrintReport}>
                        { isPrintBtnLoading ? <Spinner size='sm' /> : `Print Report For Page.No ${currentPage}` }
                    </CButton>
                </CCol>
            </CRow>

            <CRow className="mt-4">
                <CCol>
                { clicked === false ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <h5 className="text-danger">Please Select Outlet, Category and Required Date</h5>
                    </div>
                    ) : (
                        <>
                            <CPagination className="justify-content-end" style={{ cursor: 'pointer' }}>
                                <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                    <CFormSelect
                                        className="ml-3"
                                        style={{ width: 'auto' }}
                                        value={recordsPerPage}
                                        onChange={(e) => {
                                                const limit = e.target.value;
                                                const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
                                                const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
                                                setRecordsPerPage(e.target.value);
                                                getItemWiseReport(adjustedStartDate, adjustedEndDate, selectedOutlet, selectedCategory, productId, currentPage, limit);
                                            }
                                        }
                                    >
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={200}>200</option>
                                        <option value={500}>500</option>
                                    </CFormSelect>
                                </div>
                                <CPaginationItem
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                Previous
                                </CPaginationItem>
                                {totalPages > 15 ? (
                                <>
                                    {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                    {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}
            
                                    {Array.from({ length: 5 }, (_, i) => {
                                    const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;
                                    if (pageNumber <= totalPages) {
                                        return (
                                        <CPaginationItem
                                            key={pageNumber}
                                            active={pageNumber === currentPage}
                                            onClick={() => handlePageChange(pageNumber)}
                                        >
                                            {pageNumber}
                                        </CPaginationItem>
                                        );
                                    }
                                    return null;
                                    })}
            
                                    {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                    {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                </>
                                ) : (
                                Array.from({ length: totalPages }, (_, i) => (
                                    <CPaginationItem
                                    key={i + 1}
                                    active={i + 1 === currentPage}
                                    onClick={() => handlePageChange(i + 1)}
                                    >
                                    {i + 1}
                                    </CPaginationItem>
                                ))
                                )}
                                <CPaginationItem
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                                >
                                Next
                                </CPaginationItem>
                            </CPagination>

                            <CTable striped bordered hover responsive>
                                <CTableHead>
                                    <CTableRow style={{ fontSize: '15px' }}>
                                        <CTableHeaderCell>Sr.no</CTableHeaderCell>
                                        <CTableHeaderCell>Date</CTableHeaderCell>
                                        <CTableHeaderCell>#</CTableHeaderCell>
                                        <CTableHeaderCell>Item</CTableHeaderCell>
                                        <CTableHeaderCell>Quantity</CTableHeaderCell>
                                        <CTableHeaderCell>MRP</CTableHeaderCell>
                                        <CTableHeaderCell>Rate</CTableHeaderCell>
                                        <CTableHeaderCell>Addon</CTableHeaderCell>
                                        <CTableHeaderCell>Discount</CTableHeaderCell>
                                        <CTableHeaderCell>Taxable</CTableHeaderCell>
                                        <CTableHeaderCell>Tax</CTableHeaderCell>
                                        <CTableHeaderCell>Total</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                    {loading2 ? (
                                        <CTableRow>
                                            <CTableDataCell colSpan="12" className="text-center">
                                                <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                                                {/* <p>Loading Data...</p> */}
                                            </CTableDataCell>
                                        </CTableRow>
                                    ) : (
                                        filteredData?.length > 0 ? (
                                            <>
                                                {filteredData.map((product, index) => (
                                                    <CTableRow key={product?.id} style={{ fontSize: '15px' }}>
                                                        <CTableDataCell>{index + 1}</CTableDataCell>
                                                        <CTableDataCell>{product?.date}</CTableDataCell>
                                                        <CTableDataCell>{product?.order_no}</CTableDataCell>
                                                        <CTableDataCell style={{ width: '28%', wordBreak: "break-word" }}>
                                                            <span className='text-muted fw-semibold'>{product?.category_name} {" >> "}</span><span className='fw-semibold' style={{ color: 'purple' }}>{product?.product_name}</span>
                                                            { product?.orderStatus === "CANCELLED" ?
                                                                <>
                                                                    <br />
                                                                    <CBadge color="danger" size='sm' className="mt-1 w-50">CANCELLED</CBadge>
                                                                </>
                                                                : null
                                                            }
                                                        </CTableDataCell>
                                                        <CTableDataCell className="text-center">{product?.quantity}</CTableDataCell>
                                                        <CTableDataCell className="text-end">{product?.mrpRate ? `₹ ${product?.mrpRate?.toFixed(2)}` : "------"}</CTableDataCell>
                                                        <CTableDataCell className="text-end">{product?.rate ? `₹ ${product?.rate?.toFixed(2)}` : "------"}</CTableDataCell>
                                                        <CTableDataCell className="text-end">{product?.totalAddonOfferPrice ? `₹ ${product?.totalAddonOfferPrice?.toFixed(2)}` : "------"}</CTableDataCell>
                                                        <CTableDataCell className="text-end text-danger">₹ {product?.discount?.toFixed(2)}</CTableDataCell>
                                                        <CTableDataCell className="text-end">₹ {product?.taxable?.toFixed(2)}</CTableDataCell>
                                                        <CTableDataCell className="text-end">₹ {product?.taxAmount?.toFixed(2)}</CTableDataCell>
                                                        <CTableDataCell className="text-end text-success fw-semibold">₹ {product?.grand_total?.toFixed(2)}</CTableDataCell>
                                                    </CTableRow>
                                                ))}
                                                {/* Calculate total sales and total revenue */}
                                                {currentPage === totalPages && (
                                                    <CTableRow className="fw-bold">
                                                        <CTableDataCell colSpan="4" className="text-end">Total <span className='text-secondary fw-semibold'>(Excluding Cancelled Items)</span></CTableDataCell>
                                                        <CTableDataCell className="text-center text-muted">
                                                            {totals?.totalQuantity}
                                                        </CTableDataCell>
                                                        <CTableDataCell className="text-end text-muted">
                                                            ₹ {totals?.totalMrpRate?.toFixed(2)}
                                                        </CTableDataCell>
                                                        <CTableDataCell className="text-end text-muted">
                                                            ₹ {totals?.totalRate?.toFixed(2)}
                                                        </CTableDataCell>
                                                        <CTableDataCell className='text-end text-muted'>
                                                            ₹ {totals?.totalAddonOfferPrice?.toFixed(2)}
                                                        </CTableDataCell>
                                                        <CTableDataCell className='text-end text-muted'>
                                                            ₹ {totals?.totalDiscount?.toFixed(2)}
                                                        </CTableDataCell>
                                                        <CTableDataCell className='text-end text-muted'>
                                                            ₹ {totals?.totalTaxable?.toFixed(2)}
                                                        </CTableDataCell>
                                                        <CTableDataCell className='text-end text-muted'>
                                                            ₹ {totals?.totalTaxAmount?.toFixed(2)}
                                                        </CTableDataCell>
                                                        <CTableDataCell className="text-end text-success">
                                                            ₹ {totals?.totalGrandTotal?.toFixed(2)}
                                                        </CTableDataCell>
                                                    </CTableRow>
                                                )}
                                            </>
                                        ) : (
                                            <CTableRow>
                                                <CTableDataCell colSpan="12" className="text-center text-danger fw-5">
                                                    No Data Found
                                                </CTableDataCell>
                                            </CTableRow>
                                        )
                                    )}
                                </CTableBody>
                            </CTable>

                            <CPagination className="justify-content-end" style={{ cursor: 'pointer' }}>
                                <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                    <CFormSelect
                                        className="ml-3"
                                        style={{ width: 'auto' }}
                                        value={recordsPerPage}
                                        onChange={(e) => {
                                            const limit = e.target.value;
                                            const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
                                            const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
                                            setRecordsPerPage(e.target.value);
                                            getItemWiseReport(adjustedStartDate, adjustedEndDate, selectedOutlet, selectedCategory, productId, currentPage, limit);
                                        }
                                    }
                                    >
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={200}>200</option>
                                        <option value={500}>500</option>
                                    </CFormSelect>
                                </div>
                                <CPaginationItem
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    Previous
                                </CPaginationItem>
                                {totalPages > 15 ? (
                                    <>
                                    {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                    {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}
                
                                    {Array.from({ length: 5 }, (_, i) => {
                                        const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;
                                        if (pageNumber <= totalPages) {
                                        return (
                                            <CPaginationItem
                                            key={pageNumber}
                                            active={pageNumber === currentPage}
                                            onClick={() => handlePageChange(pageNumber)}
                                            >
                                            {pageNumber}
                                            </CPaginationItem>
                                        );
                                        }
                                        return null;
                                    })}
                
                                    {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                    {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                    </>
                                ) : (
                                    Array.from({ length: totalPages }, (_, i) => (
                                    <CPaginationItem
                                        key={i + 1}
                                        active={i + 1 === currentPage}
                                        onClick={() => handlePageChange(i + 1)}
                                    >
                                        {i + 1}
                                    </CPaginationItem>
                                    ))
                                )}
                                <CPaginationItem
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    Next
                                </CPaginationItem>
                            </CPagination>
                        </>
                    ) }

                </CCol>
            </CRow>
        </div>
    )
}

export default ItemWiseReport