import React, { useState, useEffect } from 'react';
import { CButton, CCol, CFormLabel, CFormSelect, CPagination, CPaginationItem, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import ReactDatePicker from "react-datepicker";
import Select from 'react-select';
import axios from '../../api/axios';
import { toast } from 'react-toastify';

const StockTransactionReport = () => {
    const accessToken = localStorage.getItem("authToken");
    const [subBtnLoading, setsubBtnLoading] = useState(false);
    const [printBtnLoading, setPrintBtnLoading] = useState(false);
    const [outlets, setOutlets] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [rawMaterialList, setRawMaterialList] = useState([]);
    const [rmMaincategoryList, setRmMaincategoryList] = useState([]);
    const [selectedRMCategory, setSelectedRMCategory] = useState('');
    const [selectedRawMaterials, setSelectedRawMaterials] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState('50');
    const [totalPages, setTotalPages] = useState('');
    const [StockReportData, setStockReportData] = useState([]);
    const [allStockReportData, setAllStockReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [outletName, setOutletName] = useState('');
    const [clicked, setClicked] = useState(false);
    const [selectedTransactionType, setSelectedTransactionType] = useState("");

    const options = rmMaincategoryList.map((rmcat) => ({
        value: rmcat?._id,
        label: rmcat?.name,
    }));   

    const rawMaterialOptions = rawMaterialList.map((rm) => ({
        value: rm?._id,
        label: rm?.name,
    }));

    const getOutlets = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("outlet", { headers });

            if (response?.data?.status === true) {
                console.log("outlets :", response.data.data);
                const activeOutlets = response?.data?.data?.outlets.filter((active) => active?.status === "ACTIVE");
                setOutlets(activeOutlets);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getOutletName = async (e) => {
        const value = outlets?.filter(val => val?._id === e)
        setOutletName(value[0]?.name)
    };

    const getRawMaterialsMainCategory = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/rm-main-category/get", {
                headers,
            });

            if (response?.data?.status === true) {
                response?.data?.data?.categories.forEach((item, index) => {
                    item.serialNumber = index + 1;
                });
                console.log("Raw Material catergory :", response?.data?.data?.categories);

                setRmMaincategoryList(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getRawMaterials = async (categoryId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            console.log("categoryId :", categoryId);

            const response = await axios.get(`raw-material`, { headers });

            if (response?.data?.status === true) {
                response?.data?.data?.rawMaterials.forEach((item, index) => {
                    item.serialNumber = index + 1;
                });
                console.log("rawmaterials :", response?.data?.data?.rawMaterials);
                const filteredRawmaterials = response?.data?.data?.rawMaterials?.filter((fi) => fi.mainCategory._id === categoryId);
                console.log("filteredRaw :", filteredRawmaterials);

                setRawMaterialList(filteredRawmaterials);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getStockReport = async (page, limit, outlet, rmCategory, rawMaterial, transaction) => {
        setLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }

            let url = `stock-transactions/getOutletStockTransactions?fromDate=${startDate.toISOString().split("T")[0]}&toDate=${endDate.toISOString().split("T")[0]}&page=${page}&limit=${limit}`

            if (outlet) {
                url += `&outletId=${outlet}`;
            }

            if (rmCategory) {
                url += `&mainCategory=${rmCategory}`;
            }

            if (rawMaterial) {
                url += `&rawMaterialId=${rawMaterial}`;
            }

            if (transaction) {
                url += `&transactionType=${transaction}`;
            }

            const response = await axios.get(url, { headers });

            console.log("response of report :", response?.data?.data);
            setStockReportData(response?.data?.data?.outletStockTransactionRecords);
            setTotalPages(response?.data?.data?.paginationData?.NumberOfPages);
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const getAllStockReport = async (outlet, rmCategory, rawMaterial, transaction) => {
        setPrintLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }

            let url = `stock-transactions/getOutletStockTransactions?startDate=${startDate.toISOString().split("T")[0]}&endDate=${endDate.toISOString().split("T")[0]}`

            if (outlet) {
                url += `&outletId=${outlet}`;
            }

            if (rmCategory) {
                url += `&mainCategory=${rmCategory}`;
            }

            if (rawMaterial) {
                url += `&rawMaterialId=${rawMaterial}`;
            }

            if (transaction) {
                url += `&transactionType=${transaction}`;
            }

            const response = await axios.get(url, { headers });

            console.log("response of report :", response?.data?.data);
            setAllStockReportData(response?.data?.data?.outletStockTransactionRecords);
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setPrintLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setClicked(true);
        setsubBtnLoading(true);
        setCurrentPage(1);
        await getStockReport(currentPage, recordsPerPage, selectedOutlet, selectedRMCategory, selectedRawMaterials, selectedTransactionType);
        setsubBtnLoading(false);
    }

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
        getStockReport(page, recordsPerPage, selectedOutlet, selectedRMCategory, selectedRawMaterials, selectedTransactionType);
    }

    const handlePrintReport = async () => {
        setPrintBtnLoading(true);
        // Fetch all order data for the report
        await getAllStockReport(selectedOutlet, selectedRMCategory, selectedRawMaterials, selectedTransactionType);
        setPrintBtnLoading(false);


        const formatDate = (date) => {
            return new Date(date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        };

        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        // <th>Stock</th>
        // <td>${record?.currentStock} ${record?.rawMaterial?.unit}</td>
        const tableContent = `
          <table style="border-collapse: collapse; width: 100%;">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Date & Time</th>
                    <th>Raw Materials Details</th>
                    <th>Storage Location</th>
                    <th>Type</th>
                    <th style="text-align: center">Quantity</th>
                </tr>
            </thead>
            <tbody>
              ${allStockReportData.length > 0 ?
                allStockReportData.map((record, index) => `
                    <tr>
                        <td>${index + 1}</td>
                        <td>${new Date(record?.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' })} <br /> <span>${new Date(record?.date).toLocaleTimeString('en-GB')}</span> </td>
                        <td>
                            <span style="font-weight: 500">${record?.rawMaterial?.name}</span> <br /> 
                            <span style="font-size: 14px">${record?.rawMaterial?.mainCategory?.name}</span>  > <span style={{ font-size: 13px }}>${record?.rawMaterial?.subCategory?.name}</span>  <br />
                            <span style="font-size: 12px">${record?.remark}</span>
                        </td>
                        <td style="font-size: 14px">${record?.storageLocationDetails ? `${record?.storageLocationDetails?.name} - ${record?.storageLocationDetails?.city}, ${record?.storageLocationDetails?.state}` : '--------'}</td>
                        <td>
                            <span>${record?.type}</span> <br />
                            <span style="font-size: 8px">(${record?.transactionType})</span>
                        </td>
                        <td style="text-align: center; width: 120px">${record?.quantity} ${record?.rawMaterial?.unit}</td>
                    </tr>
                `).join("") :
                `<tr>
                  <td colspan="10" style="border: 1px solid black; padding: 8px; text-align: center;">No Data Found</td>
                </tr>`
            }
            </tbody>
          </table>
        `;

        const win = window.open("", "", "width=900,height=600");
        win.document.write(`
          <html>
            <head>
              <title>STOCK TRANSACTION REPORT - For ${outletName} </title>
              <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body onload="window.print();window.close()">
              <div style="display: flex; justify-content: space-between; align-items: center">
                <h4>STOCK TRANSACTION REPORT - (For ${outletName}) </h4>
                <span>From [${formattedStartDate} to ${formattedEndDate}]</span>
              </div>
              <div>${tableContent}</div>
            </body>
          </html>
        `);
        win.document.close();
    };

    // Handle RM Category selection
    const handleRMCategoryChange = (selectedOption) => {
        const categoryId = selectedOption ? selectedOption.value : '';
        setSelectedRMCategory(categoryId);
        if (categoryId) {
            getRawMaterials(categoryId);
        } else {
            setRawMaterialList([]);
        }
    };

    const handleTransactionTypeChange = (data) => {
        setSelectedTransactionType(data);
    }

    useEffect(() => {
        getOutlets();
        getRawMaterialsMainCategory();
    }, []);

    useEffect(() => {
        const fetchOrderData = () => {

            const now = new Date();
            const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

            // Get the current date and set start and end of the day
            const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

            // Start of the current day (00:00:00.000)
            const start = new Date(currentDate.setHours(23, 59, 59, 999));

            // End of the current day (23:59:59.999)
            const end = new Date(now.setHours(23, 59, 59, 999));

            // Set the start date and end date
            setStartDate(start);
            setEndDate(end);

        };

        fetchOrderData();
    }, []);

    console.log("options :", options);
    

    return (
        <div className='container'>
            <h5>OUTLET WISE STOCK TRANSACTION REPORT</h5>
            <CRow className='mt-3'>
                <CCol md={4} lg={4}>
                    <CFormLabel className="fw-semibold">Select Outlet</CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => {
                            setSelectedOutlet(e.target.value);
                            getOutletName(e.target.value);
                        }}
                    >
                        <option value="">All Outlets</option>

                        {outlets.map((outName, index) => {
                            return (
                                <option key={index} value={outName?._id}>
                                    {outName?.name}
                                </option>
                            );
                        })}
                    </CFormSelect>
                </CCol>

                <CCol md={4} lg={4}>
                    <CFormLabel className="fw-semibold">Select Transaction Type</CFormLabel>
                    <CFormSelect id='selectField' value={selectedTransactionType} onChange={(e) => handleTransactionTypeChange(e.target.value)}>
                        <option value="">Select Transaction Type</option>
                        <option value="CENTRAL-INWARD">CENTRAL-INWARD</option>
                        <option value="SALE">SALE</option>
                        <option value="CENTRAL-TRANSFER">CENTRAL-TRANSFER</option>
                        <option value="DAMAGE">DAMAGE</option>
                        <option value="ADJUSTMENT">ADJUSTMENT</option>
                        <option value="TESTING">TESTING</option>
                        <option value="ORDER-CANCELLATION">ORDER-CANCELLATION</option>
                    </CFormSelect>
                </CCol>

                <CCol md={2} lg={2}>
                    <CFormLabel className="fw-semibold">Select Start Date</CFormLabel>
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd-MM-yyyy"  // Set the date format here
                        placeholderText="Select Start Date"
                        className="form-control"
                    />
                </CCol>

                <CCol md={2} lg={2}>
                    <CFormLabel className="fw-semibold">Select End Date</CFormLabel>
                    <ReactDatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd-MM-yyyy"  // Set the date format here
                        placeholderText="Select End Date"
                        className="form-control"
                    />
                </CCol>
                
            </CRow>

            <CRow className='mt-2'>
                <CCol md={3} lg={3}>
                    <CFormLabel className="fw-semibold">Select Main RM Category</CFormLabel>
                    <Select
                        id="selectField"
                        name="selectField"
                        value={options.find(option => option.value === selectedRMCategory)}
                        onChange={(selectedOption) => {
                            handleRMCategoryChange(selectedOption);
                        }}
                        options={options}
                        placeholder="Select Main RM Categories"
                        isSearchable
                        isClearable
                    />
                </CCol>
                <CCol md={3} lg={3}>
                    <CFormLabel className="fw-semibold">Select Raw Material</CFormLabel>
                    <Select
                        id="selectRawMaterial"
                        name="selectRawMaterial"
                        value={rawMaterialOptions.find(option => option.value === selectedRawMaterials)}
                        onChange={(selectedOption) => {
                            setSelectedRawMaterials(selectedOption ? selectedOption.value : '');
                        }}
                        options={rawMaterialOptions}
                        placeholder="Select Raw Material"
                        isSearchable
                        isClearable
                    />
                </CCol>
                <CCol md="3" lg="3" className="text-center">
                    <CFormLabel className='text-white'>.</CFormLabel>
                    <CButton className="btn rounded-pill w-100" onClick={handleSubmit}>
                        {subBtnLoading ? <CSpinner size="sm" /> : "Submit"}
                    </CButton>
                </CCol>
                <CCol md="3" lg="3" className="text-center">
                    <CFormLabel className='text-white'>.</CFormLabel>
                    <CButton className="btn rounded-pill w-100 bg-success border-success" disabled={printBtnLoading} onClick={handlePrintReport}>
                        {printBtnLoading ? <CSpinner size="sm" /> : "Print Report"}
                    </CButton>
                </CCol>
            </CRow>

            <CRow className="mt-3">
                <CCol>
                    { clicked === false ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                            <h5 className="text-muted">Please Select Outlet, Main RM Category and Raw Material</h5>
                        </div>
                    ) : (
                        <>
                            {/* Dropdown for selecting records per page */}
                            <CRow>
                                <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                                    <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                        <CFormSelect
                                            className="ml-3"
                                            style={{ width: 'auto' }}
                                            value={recordsPerPage}
                                            onChange={(e) => {
                                                const selectedRecordsPerPage = e.target.value;
                                                if (selectedRecordsPerPage !== recordsPerPage) { // Only proceed if there's a change
                                                    setRecordsPerPage(selectedRecordsPerPage);
                                                    getStockReport(currentPage, selectedRecordsPerPage, selectedOutlet, selectedRMCategory, selectedRawMaterials, selectedTransactionType);
                                                }
                                            }}
                                        >
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={200}>200</option>
                                            <option value={300}>300</option>
                                            <option value={400}>400</option>
                                            <option value={500}>500</option>
                                        </CFormSelect>
                                    </div>
                                    <CPaginationItem
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Previous
                                    </CPaginationItem>
                                    {totalPages > 15 ? (
                                        <>
                                            {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                            {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                                const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                                                // Avoid rendering out-of-range page numbers
                                                if (pageNumber <= totalPages) {
                                                    return (
                                                        <CPaginationItem
                                                            key={pageNumber}
                                                            active={pageNumber === currentPage}
                                                            onClick={() => handlePageChange(pageNumber)}
                                                        >
                                                            {pageNumber}
                                                        </CPaginationItem>
                                                    );
                                                }
                                                return null;
                                            })}

                                            {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                            {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                        </>
                                    ) : (
                                        // If totalPages is 15 or less, just display all page numbers
                                        Array.from({ length: totalPages }, (_, i) => (
                                            <CPaginationItem
                                                key={i + 1}
                                                active={i + 1 === currentPage}
                                                onClick={() => handlePageChange(i + 1)}
                                            >
                                                {i + 1}
                                            </CPaginationItem>
                                        ))
                                    )
                                    }
                                    <CPaginationItem
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </CPaginationItem>
                                </CPagination>
                            </CRow>

                            <CTable striped bordered hover responsive>
                                <CTableHead>
                                    <CTableRow style={{ fontSize: '15px' }}>
                                        <CTableHeaderCell>#</CTableHeaderCell>
                                        <CTableHeaderCell>Date & Time</CTableHeaderCell>
                                        <CTableHeaderCell className='w-25' style={{ width: '10px' }}>Raw Materials Details</CTableHeaderCell>
                                        <CTableHeaderCell>Storage Location</CTableHeaderCell>
                                        <CTableHeaderCell>Type</CTableHeaderCell>
                                        <CTableHeaderCell className='text-center'>Quantity</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                    {loading ? (
                                        <CTableRow>
                                            <CTableDataCell colSpan="10" className="text-center">
                                                <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                                            </CTableDataCell>
                                        </CTableRow>
                                    ) :
                                        StockReportData?.length > 0 ? (
                                            <>
                                                {StockReportData?.map((stock, index) => (
                                                    <CTableRow key={index} style={{ fontSize: '15px' }}>
                                                        <CTableDataCell>{(currentPage - 1) * recordsPerPage + index + 1}</CTableDataCell>
                                                        <CTableDataCell>
                                                            {new Date(stock?.date).toLocaleDateString('en-GB', {
                                                                year: 'numeric',
                                                                month: 'numeric',
                                                                day: 'numeric',
                                                            })} <br />
                                                            <span>
                                                                {new Date(stock?.date).toLocaleTimeString('en-GB')}
                                                            </span>
                                                        </CTableDataCell>
                                                        <CTableDataCell>
                                                            <span className='fw-semibold'>{stock?.rawMaterial?.name}</span> <br />
                                                            <span className='text-primary' style={{ fontSize: '14px' }}>{stock?.rawMaterial?.mainCategory?.name}</span> {" > "} <span style={{ fontSize: '14px', color: 'purple' }}>{stock?.rawMaterial?.subCategory?.name}</span> <br />
                                                            <div style={{ fontSize: '12px', wordWrap: 'break-word', whiteSpace: 'normal' }}>{stock?.remark}</div>
                                                        </CTableDataCell>
                                                        <CTableDataCell>
                                                            {stock?.storageLocationDetails ? (
                                                                    <>
                                                                        <span>{stock?.storageLocationDetails?.name}</span> <br />
                                                                        <span className='text-muted'>{stock?.storageLocationDetails?.city}</span>, {" "}
                                                                        <span className='text-muted'>{stock?.storageLocationDetails?.state}</span>
                                                                    </>
                                                                ) : '-----------'
                                                            }
                                                        </CTableDataCell>
                                                        {/* <CTableDataCell>{stock?.currentStock?.toFixed(2)} {stock?.rawMaterial?.unit}</CTableDataCell> */}
                                                        <CTableDataCell className='fw-semibold' style={{ color: stock?.type === "CREDIT" ? "green" : "orangered" }}>
                                                            <span>{stock?.type}</span> <br />
                                                            <span className='text-muted' style={{ fontSize: '10px' }}>({stock?.transactionType})</span>
                                                        </CTableDataCell>
                                                        <CTableDataCell className='text-center'>{stock?.quantity?.toFixed(2)} {stock?.rawMaterial?.unit}</CTableDataCell>
                                                    </CTableRow>
                                                ))}
                                            </>
                                        ) : (
                                            <CTableRow>
                                                <CTableDataCell colSpan="9" className="text-center text-danger fw-semibold">
                                                    Select Outlet And Raw Material Categroy
                                                </CTableDataCell>
                                            </CTableRow>
                                        )}
                                </CTableBody>
                            </CTable>

                            <CRow>
                                <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                                    <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                        <CFormSelect
                                            className="ml-3"
                                            style={{ width: 'auto' }}
                                            value={recordsPerPage}
                                            onChange={(e) => setRecordsPerPage(e.target.value)}
                                        >
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={200}>200</option>
                                            <option value={300}>300</option>
                                            <option value={400}>400</option>
                                            <option value={500}>500</option>
                                        </CFormSelect>
                                    </div>
                                    <CPaginationItem
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Previous
                                    </CPaginationItem>
                                    {totalPages > 15 ? (
                                        <>
                                            {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                            {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                                const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                                                // Avoid rendering out-of-range page numbers
                                                if (pageNumber <= totalPages) {
                                                    return (
                                                        <CPaginationItem
                                                            key={pageNumber}
                                                            active={pageNumber === currentPage}
                                                            onClick={() => handlePageChange(pageNumber)}
                                                        >
                                                            {pageNumber}
                                                        </CPaginationItem>
                                                    );
                                                }
                                                return null;
                                            })}

                                            {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                            {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                        </>
                                    ) : (
                                        // If totalPages is 15 or less, just display all page numbers
                                        Array.from({ length: totalPages }, (_, i) => (
                                            <CPaginationItem
                                                key={i + 1}
                                                active={i + 1 === currentPage}
                                                onClick={() => handlePageChange(i + 1)}
                                            >
                                                {i + 1}
                                            </CPaginationItem>
                                        ))
                                    )
                                    }
                                    <CPaginationItem
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </CPaginationItem>
                                </CPagination>
                            </CRow>
                        </>
                    )
                }
                </CCol>
            </CRow>
        </div>
    )
}

export default StockTransactionReport